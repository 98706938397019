import { Link } from "@chakra-ui/react";
import Icons from "../Icons";

export const CompanyDetailsRenderer = (props: any) => {
  return (
    <div className="flex w-full items-center gap-2">
      <Link href={props?.data?.companyWebsite} target="_blank">
        <span className="text-[#5024DC]">{props?.data?.companyName}</span>
      </Link>
      {props?.data?.companyLinkedInURL && (
        <Link href={props?.data?.companyLinkedInURL} target="_blank">
          <Icons.LinkedInIcon className="text-lg text-[#0077B5]" />
        </Link>
      )}
    </div>
  );
};
