import Icons from "@/components/Icons";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import CheckCircleOutline from "@/components/Icons/SvgIcons/CheckCircleOutline";
import { PricingType } from "@/types/pricing.types";

interface Props {
  pricing: PricingType;
  isLoading?: boolean;
  openFrom?: string;
  isAnnual?: boolean;
  isPlanSwitched?: boolean;
  onClick: (params: {
    selectedPrice: SelectedPriceType;
    isUpgrade?: boolean;
    isDowngrade?: boolean;
  }) => void;
  currentPlan?: {
    credits: string;
    planType: string;
    amount: number;
    priceId?: string;
  };
}

export interface SelectedPriceType {
  price: number;
  credits: number;
  priceId: string;
}

export const PriceBox = ({
  pricing,
  isLoading = false,
  onClick,
  currentPlan,
  openFrom,
  isAnnual,
  isPlanSwitched,
}: Props) => {
  const [selectedPrice, setSelectedPrice] = useState<SelectedPriceType | null>(
    null,
  );

  const isCurrentPlan =
    currentPlan?.planType?.toLocaleLowerCase() ===
    pricing.title?.toLocaleLowerCase();

  const getActionBtnText = () => {
    if (pricing.isContactRequired) {
      return "Contact us";
    }

    if (!currentPlan?.planType) {
      if (selectedPrice) {
        return "Subscribe Now";
      } else {
        return "Select Credits";
      }
    }

    if (isCurrentPlan) {
      if (currentPlan?.priceId === selectedPrice?.priceId) {
        return "Current Plan";
      } else if (selectedPrice && currentPlan) {
        if (Number(currentPlan?.credits || "0") < selectedPrice.credits) {
          return "Upgrade Plan";
        } else {
          return "Downgrade Plan";
        }
      } else {
        return "Select Credits";
      }
    } else {
      if (selectedPrice && currentPlan) {
        if (Number(currentPlan?.credits || "0") < selectedPrice.credits) {
          return "Upgrade Plan";
        } else {
          return "Downgrade Plan";
        }
      } else {
        return "Select Credits";
      }
    }
  };

  const getIsOptionDisabled = (tier: SelectedPriceType) => {
    if (openFrom === "dashboard") {
      // @ts-ignore
      if (currentPlan?.amount >= tier.price) {
        return true;
      }
    } else {
      return currentPlan?.priceId === tier.priceId;
    }
  };

  useEffect(() => {
    if (currentPlan?.priceId && pricing.tiers) {
      const selectedPrice = pricing.tiers.find(
        (tier) => tier.priceId === currentPlan?.priceId,
      );
      if (selectedPrice) {
        setSelectedPrice(selectedPrice);
      }
    }
  }, [currentPlan, pricing.tiers]);

  useEffect(() => {
    if (selectedPrice && pricing.tiers && pricing?.tiersAnnual) {
      if (isAnnual) {
        const indexOfSelectedMonthTier = pricing.tiers.findIndex(
          (tier) => tier.priceId === selectedPrice.priceId,
        );
        setSelectedPrice(pricing.tiersAnnual[indexOfSelectedMonthTier]);
      } else if (isPlanSwitched) {
        const indexOfSelectedAnnualTier = pricing.tiersAnnual.findIndex(
          (tier) => tier.priceId === selectedPrice.priceId,
        );
        setSelectedPrice(pricing.tiers[indexOfSelectedAnnualTier]);
      }
    }
  }, [isAnnual]);

  const actionBtnText = getActionBtnText();

  return (
    <div
      className={`flex h-full flex-col rounded-[14px] bg-white !font-title`}
      style={{
        borderTop: "4px solid #5024DC",
        boxShadow: "-0.5px 0.5px 2px #C03EDC, 0.5px -0.5px 2px #5024DC",
        borderRadius: "8px",
      }}
    >
      <div className="">
        <div
          className={`rounded-t-[4px] ${
            pricing?.mostPopular ? "gradientBg text-white" : "bg-[#F5F3FF]"
          } p-3.5`}
        >
          <h2 className="text-[1rem] font-semibold">{pricing.title} </h2>
        </div>
        <div className="space-y-3.5 p-3.5 text-2xl font-medium">
          <div>
            {typeof pricing.defaultPrice === "number" ? (
              <p className="font-bold">
                {selectedPrice
                  ? `$${selectedPrice.price.toLocaleString("en-US")}`
                  : `$${
                      isAnnual
                        ? pricing?.annualDefaultPrice.toLocaleString("en-US")
                        : pricing.defaultPrice.toLocaleString("en-US")
                    }/month`}
              </p>
            ) : (
              <p className="font-bold">
                {isAnnual ? pricing?.annualDefaultPrice : pricing.defaultPrice}
              </p>
            )}

            <p className="text-sm text-[#7a7a7a]">{pricing.description}</p>
          </div>
          <div className={`${pricing.isContactRequired ? "invisible" : ""}`}>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    as={"button"}
                    className="w-full rounded-md border border-[#D0D4DC] bg-white px-4 py-2 text-sm font-medium text-black hover:bg-[#E6E8EC] focus:outline-none"
                  >
                    <span className="flex items-center justify-between space-x-3">
                      <span className="truncate">
                        {selectedPrice
                          ? `${selectedPrice.credits.toLocaleString("en-US")} credits / ${isAnnual ? "yr" : "mo"}`
                          : "Select credits"}
                      </span>
                      <Icons.ArrowDownIcon
                        className={`text-base text-black duration-300 ${isOpen ? "origin-center rotate-180" : ""}`}
                        aria-hidden="true"
                      />
                    </span>
                  </MenuButton>
                  <MenuList
                    sx={{
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      padding: "6px",
                    }}
                  >
                    {(isAnnual ? pricing.tiersAnnual : pricing.tiers)?.map(
                      (tier, index) => (
                        <MenuItem
                          key={`pricing-tier-${index}`}
                          sx={{
                            paddingX: "5.5px",
                            _hover: {
                              borderRadius: "5.5px",
                            },
                            flexDir: "column",
                            alignItems: "left",
                          }}
                          className={`${selectedPrice?.priceId === tier.priceId ? "!bg-[#F5F3FF]" : ""} !rounded-[5.5px]`}
                          onClick={() => {
                            setSelectedPrice(tier);
                          }}
                          isDisabled={getIsOptionDisabled(tier)}
                        >
                          <span className="text-base leading-none text-[#282C35]">
                            ${tier.price?.toLocaleString("en-US")}
                          </span>
                          <span className="pt-1.5 text-xs font-semibold leading-none text-[#717989]">
                            {tier.credits?.toLocaleString("en-US")} credits /{" "}
                            {isAnnual ? "yr" : "mo"}
                          </span>
                        </MenuItem>
                      ),
                    )}
                  </MenuList>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>

      <div className="h-[1.5px] w-full rounded-full bg-[#F0EFF1]"></div>

      <div className="grow space-y-3 p-3.5">
        {pricing?.features?.map((feature, index) => (
          <p key={`pricing-feature-${index}`} className="flex font-medium">
            <CheckCircleOutline className="mr-2 size-5" />
            <span className="text-[14.5px]">{feature}</span>
          </p>
        ))}
      </div>

      <div className="p-3.5">
        <Button
          variant={"unstyled"}
          className="!font-title !text-base !leading-none !tracking-wide"
          isDisabled={
            pricing.isContactRequired || isCurrentPlan ? false : !selectedPrice
          }
          isLoading={isLoading}
          sx={{
            width: "100%",
            bg: "#C03EDC",
            color: "#fff",
            borderRadius: "6px",
            paddingY: "9px",
            height: "auto",
            fontWeight: "medium",
            display: "flex",
            justifyContent: "center",
            _disabled: {
              bg: "#f7f8f9",
              color: "#bfc4cd",
            },
          }}
          onClick={() => {
            onClick?.({
              selectedPrice: selectedPrice as SelectedPriceType,
              isDowngrade: actionBtnText === "Downgrade Plan",
              isUpgrade: actionBtnText === "Upgrade Plan",
            });
          }}
        >
          {actionBtnText}
        </Button>
      </div>
    </div>
  );
};
