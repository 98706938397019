import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  ButtonGroup,
  HStack,
  Text,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  BoxProps,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useFilterManager, Filter } from "./useFilterManager";
import { useSearchLeadsStore } from "../store";
import Icons from "@/components/Icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMemo, useState } from "react";
import GPT from "@/assets/svgs/enrichment-gpt.svg";
// import GPT from "../path-to-your-image/GPT.png"; // Make sure to update the correct path

dayjs.extend(relativeTime);

export const FilterComponent = (props: BoxProps) => {
  const { save, list, remove, update, isSaved, isSaving, isDeleting } =
    useFilterManager();
  const [renamingIndex, setRenamingIndex] = useState<number | null>(null);
  const [newTitle, setNewTitle] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const currentFilter: Filter = useSearchLeadsStore((state) => ({
    naturalFilters: state.naturalFilters,
    sideBarInput: state.sideBarInput,
    currentSearchPayload: state.currentSearchPayload,
    assistantInput: state.assistantInput,
    valueLabels: state.valueLabels,
  }));

  const updateState = useSearchLeadsStore((state) => state.updateState);
  const savedFilters = list();

  const isSaveDisabled = useMemo(() => {
    return (
      !currentFilter.naturalFilters?.natural_query || isSaved(currentFilter)
    );
  }, [currentFilter, savedFilters, isSaved]);
  const handleApplyFilter = (filter: Filter) => {
    updateState(filter);
  };

  const handleRenameFilter = (index: number) => {
    setRenamingIndex(index);
    setNewTitle(
      savedFilters[index].name ??
        (savedFilters[index]?.naturalFilters?.query_title || ""),
    );
    onOpen();
  };

  const confirmRename = () => {
    const updatedFilter = { ...savedFilters[renamingIndex!] };
    updatedFilter.name = newTitle;
    update(updatedFilter);
    onClose();
  };

  return (
    <Box
      {...props}
      className={`flex justify-between ${props.className ? props.className : ""}`}
    >
      <HStack>
        <Icons.BsFilter size={20} />
        {/* <Text fontWeight={"normal"} fontSize={"sm"}>
          Filters
        </Text> */}
      </HStack>
      <ButtonGroup size={"xs"} isAttached className="flex">
        <IconButton
          variant={"outline"}
          aria-label="Save Filter"
          icon={<Icons.SaveIcon color="#5024dc" />}
          isLoading={isSaving}
          onClick={() => save(currentFilter)}
          isDisabled={isSaveDisabled}
        />

        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Select Filter"
            icon={<ChevronDownIcon />} // Use the GPT image as icon
          />
          <MenuList>
            {savedFilters.length > 0 ? (
              savedFilters.map((filter, index) => (
                <MenuItem as={Box} key={index}>
                  <HStack justify="space-between" width="100%">
                    <HStack gap={2} onClick={() => handleApplyFilter(filter)}>
                      <img className="" src={GPT} alt="GPT" />
                      <Box>
                        <Text>
                          {filter.name ||
                            filter.naturalFilters?.query_title ||
                            `Filter ${index + 1}`}
                        </Text>
                        <Text fontSize="xs" color="gray.500">
                          {filter.savedDate
                            ? dayjs(filter.savedDate).fromNow()
                            : "Unknown date"}
                        </Text>
                      </Box>
                    </HStack>
                    <ButtonGroup
                      onMouseUp={(e) => e.stopPropagation()}
                      zIndex={"dropdown"}
                      size="xs"
                      isAttached
                    >
                      <IconButton
                        variant={"outline"}
                        aria-label="Rename Filter"
                        icon={<Icons.EditIcon />}
                        size="xs"
                        className="!text-blue-500 hover:!bg-blue-100"
                        onClick={() => {
                          handleRenameFilter(index);
                        }}
                      />
                      <IconButton
                        aria-label="Delete Filter"
                        icon={<Icons.DeleteIcon />}
                        isLoading={isDeleting}
                        size="xs"
                        className="!text-red-500 hover:!bg-red-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          remove(index);
                        }}
                      />
                    </ButtonGroup>
                  </HStack>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No saved filters</MenuItem>
            )}
          </MenuList>
        </Menu>
      </ButtonGroup>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rename Filter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              placeholder="Enter new filter name"
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={confirmRename}>
              Confirm
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
