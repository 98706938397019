import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FilterModel } from "ag-grid-community";
import { useCallback, useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";

import ExportIcon from "@/assets/svgs/export.svg";
import { DeleteRowButton } from "@/components/DataTable/DeleteRowButton";
import { FilterSidebar } from "@/components/DataTable/FilterSidebar";
import { Table } from "@/components/DataTable/Table";
import { useExport } from "@/components/DataTable/Table/hooks/useExport";
import {
  TableStateContext,
  createTableStateStore,
} from "@/components/DataTable/Table/hooks/useGridState.store";
import { useTable } from "@/components/DataTable/Table/hooks/useTable";
import { TableSearch } from "@/components/DataTable/TableSearch";
import TrackActiveJobsModal from "@/components/HiringJobs/TrackActiveJobsModal";
import { useLeadSearchHistory } from "@/hooks/useLeadSearchHistory";
import { useTabState } from "@/pages/AccountMonitoring";
import accountMonitoringService from "@/services/accountMonitoring.service";
import { ActiveJobsType } from "@/types/accountMonitoring.types";
import { LeadSearchType } from "@/types/leads.types";
import { TableDataQueryParams } from "@/types/table.types";
import { useCreateTable } from "../Common/AccountMonitoring/hooks/useCreateTable";
import Icons from "../Icons";
import { colDefs } from "./columns/colDef";
import { SearchableColumns } from "./constants";
import { useImportModelProps } from "./hooks/useImportModel";
import { useJobTracker } from "./hooks/useTrack";
import SearchHistory from "./SearchHistory";

const HiringJobsComponent = ({ tabIndex }: { tabIndex?: number }) => {
  const exportMutation = useExport({
    sort: true,
    filters: true,
    selected: true,
    fileName: "active-jobs",
    colDefs,
    exportColumns: [
      { label: "Company", value: "companyName" },
      { label: "Job Title", value: "title" },
      { label: "Job Location(s)", value: "location" },
      { label: "Work Style(s)", value: "jobType" },
      { label: "Job Boards", value: "jobBoard" },
      { label: "Job URL", value: "jobPostedUrl" },
      { label: "Job Posted", value: "jobPosted" },
    ],
    dataSource: (param) => accountMonitoringService.getAllActiveJobs(param),
  });

  const { syncStatus } = useJobTracker();
  const tableLoading = useTable().loading;
  const tabControllerSetHandlers = useTabState((state) => state.setHandlers);
  const tabControllerSetLoading = useTabState((state) => state.setLoading);
  const tabControllerSetMetadata = useTabState((state) => state.setMetadata);
  const importModalProps = useImportModelProps();
  const { isFetching, data: leadSearchHistoryData } = useLeadSearchHistory(
    LeadSearchType.COMPANIES_HIRING,
  );

  useEffect(() => {
    if (typeof tabIndex === "undefined") return;
    tabControllerSetHandlers(tabIndex, {
      onImport: () => importModalProps.setIsOpen(true),
    });
    tabControllerSetLoading(tabIndex, tableLoading);
    tabControllerSetMetadata(tabIndex, {
      importButtonTitle: "Track Active Jobs",
    });
  }, [importModalProps.setIsOpen, tableLoading, tabIndex]);

  const { mutation: createTable, exportableRowCount } = useCreateTable(
    "activeJobs",
    true,
    true,
  );
  const onDelete = useCallback(
    (params: { rowIds: string[] | "all"; filters?: FilterModel | null }) =>
      accountMonitoringService.deleteActiveJobs({
        jobs: params.rowIds,
        filters: params.filters,
      }),
    [],
  );

  return (
    <>
      <div className="flex size-full">
        <div className="w-[300px] pt-[64px]">
          <Accordion allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Previous Search
                  </Box>
                  {isFetching ? <Spinner /> : <AccordionIcon />}
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {!!leadSearchHistoryData?.length && (
                  <SearchHistory
                    leadSearchHistoryData={leadSearchHistoryData}
                  />
                )}
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    Companies
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4} className="!p-0">
                <FilterSidebar
                  id="activejobs"
                  valuesSource={() =>
                    accountMonitoringService
                      .getHiringCompaniesOptions()
                      .then((res) => res.data)
                  }
                  gridFields={["companyName"]}
                  containerClassName="!w-full max-h-[70vh]"
                  isHideHeader
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>

        <div className="flex-1 rounded-2xl bg-white">
          <Stack
            px={5}
            py={3}
            direction="row"
            justifyContent={"space-between"}
            borderBottom={"1px solid #E5E5E5"}
          >
            <ButtonGroup fontWeight={"200"} gap={"16px"}>
              <Button
                isDisabled={createTable.isPending || exportableRowCount == 0}
                pointerEvents={createTable.isPending ? "none" : "auto"}
                fontSize={"14px"}
                fontWeight={500}
                bg="#F5F3FF"
                onClick={() => createTable.mutate()}
                leftIcon={
                  createTable.isPending ? (
                    <Spinner size="sm" color="#5024DC" />
                  ) : (
                    <Icons.PlusIcon />
                  )
                }
                rightIcon={
                  <Text opacity={exportableRowCount == 0 ? 0 : 1} color="grey">
                    {exportableRowCount}
                  </Text>
                }
                borderRadius={"20px"}
              >
                Add to Table
              </Button>
              <DeleteRowButton filter action={onDelete} />
              {syncStatus === "loading" && (
                <Spinner
                  className="place-self-center"
                  color="#5024DC"
                  size={"md"}
                  label="Importing..."
                />
              )}
            </ButtonGroup>
            <Stack direction={"row"} gap={"16px"}>
              <TableSearch gridFields={SearchableColumns} />
              <ButtonGroup>
                <Button
                  isDisabled={exportMutation.isPending}
                  px={8}
                  bg="#F5F3FF"
                  fontSize="14px"
                  fontWeight={500}
                  leftIcon={
                    exportMutation.isPending ? (
                      <Spinner
                        className="m-0 !size-[1em] p-0"
                        size="sm"
                        color="#5024DC"
                      />
                    ) : (
                      <img
                        className="size-[1em]"
                        src={ExportIcon}
                        alt="Export Icon"
                        aria-label="Export Icon"
                      ></img>
                    )
                  }
                  borderRadius={"20px"}
                  onClick={() => exportMutation.mutate()}
                  rightIcon={
                    <Text
                      opacity={exportableRowCount == 0 ? 0 : 1}
                      color="grey"
                    >
                      {exportableRowCount}
                    </Text>
                  }
                >
                  Export Data
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
          <Box pos={"relative"} h="calc(100vh - 183px)" p={3} pr={1}>
            <Table<ActiveJobsType>
              id="activeJobs"
              columns={colDefs}
              dataSource={(params: TableDataQueryParams) =>
                accountMonitoringService.getAllActiveJobs(params)
              }
            />
            <Box className="absolute bottom-[-10px] left-0 scale-75"></Box>
          </Box>
        </div>
      </div>
      <TrackActiveJobsModal {...importModalProps} />
    </>
  );
};

export const HiringJobs = ({ tabIndex }: { tabIndex?: number }) => {
  const store = useMemo(() => createTableStateStore(), []);
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <TableStateContext.Provider value={store}>
        <HiringJobsComponent tabIndex={tabIndex} />
      </TableStateContext.Provider>
    </ErrorBoundary>
  );
};
