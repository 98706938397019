import AutoPilotNode from "@/components/AutoPilotNode";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/auto-pilot/workflow").createRoute({
  component: AutoPilotWorkflowPage,
  errorComponent: () => <div>Error</div>,
});

function AutoPilotWorkflowPage() {
  return <AutoPilotNode />;
}

export default AutoPilotWorkflowPage;
