import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";

import Icons from "../Icons";
import { useEnrichStore } from "@/stores/enrich.store";

interface Props {
  subText: string;
  link: string;
}

const WatchTutorial = ({ subText, link }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );

  return (
    <div className="mb-3">
      <div className="flex items-center justify-between">
        <p className="text-sm text-[#525a69] font-medium">{subText}</p>
        <div className="flex items-center gap-2 bg-green-100 text-green-600 rounded-full px-3 py-1.5">
          <span className="text-xs font-semibold uppercase">
            {selectedEnrichments?.credits}
          </span>
          <Icons.CoinsIcon className="text-sm" />
        </div>
      </div>
      <button
        className={`flex items-center gap-x-2 rounded-full  px-2.5 py-1 text-xs font-medium text-white ${
          link ? "cursor-pointer bg-primary" : "cursor-not-allowed bg-lightGray"
        }`}
        onClick={() => {
          if (link) {
            setIsOpen(true);
          }
        }}
      >
        <Icons.PlayIcon />
        <span>Watch Tutorial</span>
      </button>

      <Modal
        isCentered
        isOpen={isOpen}
        size="3xl"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.3)" />
        <ModalContent borderRadius="lg">
          <ModalCloseButton />
          <ModalBody p={0}>
            <iframe
              src={`${link}?autoplay=1&mute=1`}
              title="YouTube video player"
              className="w-full h-[500px]"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default React.memo(WatchTutorial);
