/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CellEditingStoppedEvent,
  ColDef,
  ColumnMovedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { memo, useCallback, useEffect, useMemo } from "react";

import CustomCell from "./CustomCell";
import CustomHeader from "./CustomHeader";
import CellAction from "./PageActions/CellAction";
import TableDetailsModal from "./TableDetails/TableDetailsModal";

import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { ColumnCreatePayload, RowUpdatePayload } from "@/types/table.types";
import { convertObject } from "@/utils";
import Overlay from "./CellSelect/Overlay";
import { useCopyShortcut } from "./CellSelect/useCopyCSV";
import { useDeleteShortcut } from "./CellSelect/useDelete";
import { usePasteShortcut } from "./CellSelect/usePasteCSV";
import { useSelection } from "./CellSelect/useSelection";
import { AddRowPinned } from "./PageActions/AddRow";
import { useTableSearchStore } from "./TableSearch/tableSearch.store";
import { createANewColumnName } from "./utils";
import { userStore } from "@/stores/user.store";

interface Props {
  gridRef: React.RefObject<AgGridReact>;
  isRefetching?: boolean;
}

const DataTable = ({ gridRef, isRefetching }: Props) => {
  const { handleGridReady } = useSelection();
  usePasteShortcut();
  useCopyShortcut();
  useDeleteShortcut();
  const toast = useToast();
  const { searchText, searchedCells, searchIndex, isSearching } =
    useTableSearchStore();
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  const updateStore = useTableSearchStore((state) => state.updateStore);
  const selectedViewId = useTableStore(
    (state) => state.tableData.selectedViewId,
  );
  const isFreeUser = userStore(
    (state) => state.creditDetails?.planType?.toLocaleLowerCase() === "free",
  );
  const tableData = useTableStore((state) => state.tableData);
  const currentPage = useTableStore((state) => state.currentPage);
  const isCreatedNewColumn = useTableStore((state) => state.isCreatedNewColumn);
  const movedColumnIndex = useTableStore((state) => state.movedColumnIndex);
  const columnsData = useTableStore((state) => state.tableData.columns);
  const rowsData = useTableStore((state) => state.rowsData);
  const updateState = useTableStore((state) => state.updateState);
  const updateViews = useTableStore((state) => state.updateViews);
  const selectedRowsId = useTableStore((state) => state.selectedRowsId);
  const queryClient = useQueryClient();

  const tableId = tableData._id;
  const isAutoRunEnabled = tableData.metaData?.isEnableAutoRun;

  useEffect(() => {
    updateStore({
      rowsData,
      gridApi: gridRef.current?.api,
    });
  }, [gridRef, gridRef.current, rowsData]);
  const { mutateAsync: createColumnAsync } = useMutation({
    mutationFn: (payload: ColumnCreatePayload) =>
      tableService.insertColumn(tableId, payload),
  });

  const quaryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: ({
      tableId,
      payload,
      rowId,
    }: {
      tableId: string;
      payload: RowUpdatePayload;
      rowId: string;
    }) =>
      tableService.updateRowData({
        tableId,
        payload,
        rowId,
      }),
    onSuccess: (response) => {
      if (!response.success) {
        toast({
          title: "Error",
          description: response.error?.message || "Something went wrong",
          status: "error",
          isClosable: true,
          duration: 5000,
          position: "top-right",
        });
        return;
      }

      const updatedRowsData = rowsData.map((row) => {
        if (row._id === response?.data?.rowId) {
          if (isAutoRunEnabled) {
            response?.data?.dependentColumnsIds?.forEach((columnId: string) => {
              if (!row.cells[columnId]) {
                row.cells[columnId] = {
                  value: "queued...",
                };
              } else {
                row.cells[columnId].value = "queued...";
              }
            });
          }
        }
        return row;
      });

      updateState({
        rowsData: updatedRowsData,
      });

      gridRef.current?.api?.refreshCells({ force: true });

      if (response?.data?.dependentColumnsIds?.length) {
        quaryClient.refetchQueries({
          queryKey: ["table-running-processes", tableId],
        });
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error?.message || "Something went wrong",
        status: "error",
        isClosable: true,
        duration: 2000,
        position: "top-right",
      });
    },
  });

  const { mutateAsync: updateColumnPositionAsync } = useMutation({
    mutationFn: async (
      payload: Parameters<typeof tableService.createOrUpdateView>[0],
    ) => tableService.createOrUpdateView(payload),
  });

  const rowData = useMemo(() => {
    const rows = rowsData.map(({ cells, ...rowData }) => {
      return {
        ...convertObject(cells),
        rowData,
      };
    });
    return rows;
  }, [rowsData]);

  const colDefs = useMemo<ColDef[]>(() => {
    const columns = columnsData
      .filter((column) => !column.metaData?.isHidden)
      .map((column) => {
        const col: ColDef = {
          field: column._id,
          headerName: column.name,
          headerComponentParams: {
            columnData: column,
            isRefetching,
          },
          pinned: column.metaData?.pinned as any,
          cellStyle: { borderRight: "1px solid #805ad614" },
          cellClass: (params) => {
            const rowIndex = params?.node?.rowIndex;
            const cellId = params?.colDef?.field;

            if (
              isSearching &&
              rowIndex === searchedCells[searchIndex]?.rowIndex &&
              cellId === searchedCells[searchIndex]?.field
            ) {
              return "search-focused-cell";
            } else if (
              isSearching &&
              searchedCells.some(
                (cell) => cell.rowIndex === rowIndex && cell.field === cellId,
              )
            ) {
              return "search-matched-cell";
            } else {
              return "";
            }
          },
        };
        return col;
      });

    return [
      {
        headerName: "",
        valueGetter: (params) =>
          (params?.node?.rowIndex ?? 0) + 1 + (currentPage - 1) * 200,
        checkboxSelection: true,
        width: 120,
        pinned: "left",
        headerCheckboxSelection: true,
        suppressMovable: true,
        headerComponentParams: {
          columnData: {
            metaData: {
              isIndexColumn: true,
            },
          },
        },
      },
      ...columns,
      {
        headerName: "Add Column",
        cellClass: "pointer-events-none !border-none",
        suppressNavigable: true,
        suppressMovable: true,
      },
    ];
  }, [
    JSON.stringify(columnsData),
    isRefetching,
    isSearching,
    searchedCells,
    searchIndex,
    searchText,
    selectedViewId,
    currentPage,
  ]);

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
      customCell: memo(CustomCell),
    };
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      editable: isTablePreviewPage ? false : true,
      cellDataType: false,
      cellRenderer: "customCell",
      cellEditorPopup: false,
      suppressCellFlash: true,
      suppressColumnMoveAnimation: true,
    };
  }, []);

  const handleCellValue = async (
    event: CellEditingStoppedEvent,
    value: any,
    columnId: string,
  ) => {
    let newValue = value;

    if (/^\d+$/.test(newValue)) {
      newValue = Number(newValue);
    }

    const rowId = event.data?.rowData?._id || event.data?.rowData?.["0"]?._id;
    const bodyPayload = {
      tableId,
      rowId,
      payload: {
        columnId,
        cellValue: {
          value: newValue,
        },
      },
    };

    const updatedRowsData = rowsData.map((row) => {
      if (row._id === rowId) {
        if (!row.cells[columnId]) {
          row.cells[columnId] = {
            value: newValue,
          };
        } else {
          row.cells[columnId].value = newValue;
        }
      }
      return row;
    });

    updateState({
      rowsData: updatedRowsData,
    });

    gridRef.current?.api?.refreshCells({ force: true });
    await mutateAsync(bodyPayload);
  };

  const onCellEditingStopped = useCallback(
    async (event: CellEditingStoppedEvent) => {
      if (event.colDef?.headerName === "Add Column") {
        await createColumnAsync(
          {
            name: createANewColumnName("New Column", columnsData),
          },
          {
            onSuccess: (response) => {
              if (!response.data?.success) {
                return toast({
                  position: "top-right",
                  title:
                    response.data?.message || "Failed to create new column",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              }
              if (response.data?.data) {
                const newColId =
                  response.data?.data?.columns?.[
                    response.data?.data?.columns?.length - 1
                  ]?._id;
                updateState({
                  tableData: response.data.data,
                  isCreatedNewColumn: true,
                });

                handleCellValue(event, event.newValue, newColId);
                setTimeout(() => {
                  updateState({
                    isCreatedNewColumn: false,
                  });
                }, 2000);
              }
              toast({
                position: "top-right",
                title: "New column created",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
            },
            onError: () => {
              toast({
                position: "top-right",
                title: "Failed to create new column",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            },
          },
        );
      } else {
        const newValue = event.value;
        if (!event.valueChanged) {
          return;
        }
        //@ts-ignore
        handleCellValue(event, newValue, event.column?.colId);
      }
    },
    [tableId, mutateAsync, rowsData],
  );

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    updateState({
      selectedRowsId: selectedRows.map((row) => row.rowData._id),
    });
  }, []);

  const handleDeSelectAll = useCallback(() => {
    gridRef.current?.api?.forEachNode((row) => {
      gridRef.current?.api.getRowNode(row.id!)?.setSelected(false);
    });
  }, []);

  useEffect(() => {
    if (!selectedRowsId.length) {
      handleDeSelectAll();
    }
  }, [selectedRowsId]);

  useEffect(() => {
    if (isCreatedNewColumn) {
      if (gridRef.current) {
        const lastColumn = colDefs[colDefs.length - 2];

        if (lastColumn?.field) {
          gridRef.current.api.ensureColumnVisible(lastColumn.field!);
        }
      }
    }
  }, [isCreatedNewColumn, colDefs]);

  useEffect(() => {
    if (movedColumnIndex != null) {
      if (gridRef.current) {
        const lastColumn =
          colDefs[movedColumnIndex === 0 ? 1 : movedColumnIndex];

        if (lastColumn?.field) {
          gridRef.current.api.ensureColumnVisible(lastColumn.field!);
        }
      }
    }
  }, [movedColumnIndex, colDefs]);

  const onColumnMoved = async (event: ColumnMovedEvent) => {
    const allColumns = event.api.getAllDisplayedColumns();
    const movedColumnId = event?.column?.getColId() as string;

    if (
      (event.toIndex === 0 || event.toIndex === allColumns.length - 1) &&
      event?.finished
    ) {
      event.api.moveColumns([event.column] as any, event.toIndex - 1);
    }

    const newColumnsOrder = allColumns?.map((column, index) => {
      return {
        columnId: column.getColId(),
        columnName: column?.getColDef()?.headerName || "",
        position: index,
      };
    });

    if (event?.finished && movedColumnId) {
      const positionUpdatedColumns = allColumns
        .map((column) => {
          const columnData =
            column?.getColDef()?.headerComponentParams?.columnData;

          if (columnData?.name) {
            return columnData;
          }
        })
        ?.filter(Boolean);

      updateState({
        tableData: {
          ...tableData,
          columns: positionUpdatedColumns,
        },
      });

      updateColumnPositionAsync(
        {
          tableId,
          viewId: selectedViewId,
          bodyData: {
            columnOrder: newColumnsOrder,
          },
        },
        {
          onSuccess: (response) => {
            console.log("response", response);

            if (response.data) {
              updateViews(response.data);
              queryClient.refetchQueries({
                queryKey: ["table", tableId],
              });
            }
          },
          onError: () => {
            toast({
              position: "top-right",
              title: "Failed to update column position",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    }
  };

  useEffect(() => {
    if (isSearching && searchedCells.length > 0 && searchIndex !== -1) {
      const rowIndex = searchedCells[searchIndex].rowIndex;
      gridRef.current?.api?.ensureIndexVisible(rowIndex, "middle");
    }
  }, [searchIndex, isSearching, searchedCells]);

  return (
    <>
      {/* Search input and buttons */}
      <div
        className="ag-theme-quartz custom-scrollbar main-table"
        style={{
          width: "100%",
          height: `calc(100vh - 100px - 2.45em - ${isFreeUser ? "45px" : "0px"})`,
        }}
      >
        <AgGridReact
          context={{ tableId, viewId: selectedViewId }}
          onGridReady={handleGridReady}
          key={selectedViewId}
          rowData={rowData}
          ref={gridRef}
          headerHeight={38}
          rowHeight={35}
          columnDefs={colDefs}
          components={components}
          defaultColDef={defaultColDef}
          undoRedoCellEditing
          getRowId={(params) => params.data.rowData._id}
          onCellEditingStopped={onCellEditingStopped}
          rowSelection="multiple"
          rowMultiSelectWithClick
          suppressColumnMoveAnimation
          animateRows={false}
          suppressRowClickSelection
          suppressPaginationPanel
          onSelectionChanged={onSelectionChanged}
          suppressColumnVirtualisation={true}
          stopEditingWhenCellsLoseFocus
          enterNavigatesVerticallyAfterEdit
          onColumnMoved={onColumnMoved}
          suppressCellFocus
          suppressFieldDotNotation
          suppressAnimationFrame
        />
        <Overlay />
        <CellAction />
        <AddRowPinned tableId={tableId} gridRef={gridRef} />
      </div>
      <TableDetailsModal />
    </>
  );
};

export default DataTable;
