import TableViews from "../TableViews";
import RunningProcesses from "./RunningProcesses";
import { ButtonGroup } from "@chakra-ui/react";

const BottomPageActions = () => {
  const isTablePreviewPage =
    window.location.pathname.startsWith("/table-preview");
  // const processingData = useProcessingStore((state) => state.processingData);
  // const isAnyProcessRunning = !!processingData?.length;

  return (
    <div className="mt-1 flex h-[2em] items-center gap-5 pb-[0.25rem] pt-[0.50rem]">
      {!isTablePreviewPage && (
        <ButtonGroup size={"xs"} isAttached className="flex items-center">
          {/* <AddRow
            tableId={tableId}
            gridRef={gridRef}
            isAnyProcessRunning={isAnyProcessRunning}
          />
          <ColumnList /> */}
          <TableViews />
        </ButtonGroup>
      )}
      <RunningProcesses />
    </div>
  );
};

export default BottomPageActions;
