import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";

import HubspotIntegration from "@/components/Integrations/HubspotIntegration";

import { useProviderList } from "@/hooks/useProviderData";
import tableService from "@/services/table.service";
import { TableData } from "@/types/table.types";
import leadService from "@/services/leads.service";

type Props = {
  isConfigured: boolean;
  isOpen: boolean;
  tableData?: TableData;
  onClose: (isRefetch: boolean) => void;
};

const Configurations = ({
  isConfigured,
  isOpen,
  tableData,
  onClose,
}: Props) => {
  const toast = useToast();
  const { data: providerList } = useProviderList();
  const apiKey = providerList?.data?.find(
    (provider) => provider.name === "hubspotApiKey",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [storeOnHubspot, setStoreOnHubspot] = useState(
    !!tableData?.metaData?.storeOnHubspot,
  );

  const handleChange = (isChecked: boolean) => {
    setStoreOnHubspot(isChecked);
  };

  const handleAutoPilotHubspot = async () => {
    const payload = tableData?.metaData;
    return await leadService.runAutoPilot(
      // @ts-ignore
      payload,
      "send-leads-to-hubspot",
      tableData?._id,
    );
  };

  const updatePreferences = async () => {
    if (!apiKey) {
      toast({
        title: "Error",
        description: "Please add your hubspot API key.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    setIsLoading(true);

    const response = await tableService.updateTableDataV2(
      tableData?._id || "",
      {
        metaData: {
          storeOnHubspot,
        },
      },
    );

    if (response.success) {
      !isConfigured && storeOnHubspot && (await handleAutoPilotHubspot());
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    onClose(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isConfigured) setStoreOnHubspot(!!tableData?.metaData?.storeOnHubspot);
  }, [isConfigured, tableData]);

  return (
    <Modal
      size={"3xl"}
      isOpen={isOpen}
      onClose={() => {
        onClose(false);
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Auto Pilot - Storage</ModalHeader>
        <ModalCloseButton />
        <ModalBody className=" max-h-[500px] overflow-y-auto custom-scrollbar">
          <div className="flex items-center justify-center flex-col mt-6 mb-4">
            <Text>
              Integrate hubspot and seamlessly store your leads there.
            </Text>
          </div>
          <div className="w-[50%] mx-auto flex flex-col mb-4 gap-4">
            <div className="flex !h-[35px] items-center  !bg-white px-2 !text-sm">
              <label
                className="mr-2 cursor-pointer font-bold"
                htmlFor="auto-run-switch"
              >
                Store on Hubspot
              </label>
              <Switch
                isChecked={storeOnHubspot}
                size="md"
                onChange={(e) => {
                  handleChange(e.target.checked);
                }}
                id="auto-run-switch"
              />
            </div>
            <HubspotIntegration />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            size={"sm"}
            mr={3}
            onClick={() => onClose(false)}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            size={"sm"}
            isLoading={isLoading}
            onClick={updatePreferences}
          >
            {isConfigured ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Configurations;
