/* eslint-disable tailwindcss/no-custom-classname */
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef } from "react";

import Actions from "./Actions";
import AddFilter from "./AddFilter";
import ClearFilter from "./ClearFilter";
import ColCondition from "./ColCondition";
import ColumnList from "./ColumnList";
import Conditions from "./Conditions";

import { generateFilterPayload, generateUniqueId } from "@/lib/utils";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { FilterType } from "@/types/table.types";
import { colConditions, conditions } from "./constants";
import { FiFilter } from "react-icons/fi";

const Filters = ({ isRetching }: { isRetching: boolean }) => {
  const initialFocusRef = useRef(null);
  const toast = useToast({
    position: "top-right",
  });
  const queryClient = useQueryClient();

  const { mutateAsync: saveFilteredData, isPending } = useMutation({
    mutationFn: (
      payload: Parameters<typeof tableService.createOrUpdateView>[0],
    ) => tableService.createOrUpdateView(payload),
  });

  const selectedViewId = useTableStore(
    (state) => state.tableData.selectedViewId,
  );
  const tableFilters = useTableStore((state) => state.tableFilters);
  const isFiltering = useTableStore((state) => state.isFiltering);
  const queryParams = useTableStore((state) => state.queryParams);
  const updateState = useTableStore((state) => state.updateState);
  const columns = useTableStore((state) => state.tableData.columns);
  const tableId = useTableStore((state) => state.tableData._id);
  const tableMetaData = useTableStore((state) => state.tableData.metaData);

  const handleOpen = () => {
    if (!tableFilters?.filters?.length) {
      updateState({
        tableFilters: {
          ...tableFilters,
          open: true,
          filters: [
            {
              id: generateUniqueId(),
              column: columns[0],
              type: "input",
              colCondition: colConditions[0],
              condition: conditions[0],
              value: "",
            },
          ],
        },
        isFiltering: true,
      });
    } else {
      updateState({
        tableFilters: {
          ...tableFilters,
          open: true,
        },
        isFiltering: true,
      });
    }
  };

  const filterTable = useCallback(
    async (filters?: FilterType[]) => {
      if (!filters?.length) return;

      const { isAnyEmpty } = generateFilterPayload(filters);

      if (isAnyEmpty) return;

      saveFilteredData(
        {
          tableId,
          viewId: selectedViewId,
          bodyData: {
            filters,
          },
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries({
              queryKey: ["table", tableId],
            });
          },
          onError: (error) => {
            toast({
              title: "Error",
              description: error.message,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          },
        },
      );
    },
    [tableId, queryParams, selectedViewId],
  );

  const handleClose = () => {
    updateState({
      tableFilters: {
        ...tableFilters,
        open: false,
      },
      isFiltering: false,
    });
  };

  const debouncedFilter = useMemo(() => {
    return debounce(filterTable, 1000);
  }, [filterTable]);

  useEffect(() => {
    if (isFiltering) {
      debouncedFilter(tableFilters.filters);
    }
  }, [tableFilters.filters, debouncedFilter]);

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      isOpen={isFiltering}
      onOpen={handleOpen}
      onClose={handleClose}
      placement="bottom-start"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button
          fontWeight={500}
          size={"sm"}
          colorScheme="primary"
          variant={"ghost"}
          className="relative  !text-black"
          leftIcon={<FiFilter />}
        >
          Filters
          {tableFilters.filters?.length ? (
            <div className="absolute -right-1 -top-1 box-content size-4 rounded-full border bg-[#693DC7] text-xs text-white">
              {tableFilters.filters?.length}
            </div>
          ) : null}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="!w-fit !border-none !shadow-popover-content">
        <PopoverArrow />
        <PopoverCloseButton
          className="z-10 cursor-pointer"
          onClick={handleClose}
        />
        <PopoverBody px={4} py={3} className="relative">
          <div className="my-5 space-y-2">
            {tableFilters.filters?.map((filter, index) => (
              <div
                key={`filter_key_${index}`}
                className="flex w-[35rem] max-w-[35rem] items-center gap-x-3"
              >
                <div className="col-condition w-[50px] min-w-[50px] max-w-[50px]">
                  {index === 0 ? (
                    <p>Where</p>
                  ) : (
                    <ColCondition filter={filter} />
                  )}
                </div>
                <div className="columns w-[158px] min-w-[158px]">
                  <ColumnList columns={columns} filter={filter} />
                </div>
                <div className="conditions  w-[92px] min-w-[92px]">
                  <Conditions filter={filter} />
                </div>

                <div className="input-field w-[168px] min-w-[168px]">
                  {filter.condition.hasInput && (
                    <input
                      placeholder="Enter value"
                      className="border-gray-500 flex-1 rounded border py-[3px] pl-1.5 text-sm font-medium text-[#000000]/90 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-primary"
                      value={filter.value}
                      onChange={(e) => {
                        let value = e.target.value.toString() as
                          | string
                          | number;

                        // @ts-ignore
                        if (/^\d+$/.test(value)) {
                          value = Number(value);
                        }

                        updateState({
                          tableFilters: {
                            ...tableFilters,
                            filters: tableFilters.filters?.map((item) =>
                              item.id === filter.id ? { ...item, value } : item,
                            ),
                          },
                        });
                      }}
                    />
                  )}
                </div>
                <div className="actions  w-[80px]">
                  {index === 0 && (isPending || isRetching) && (
                    <Spinner color="#3C22BB" size="md" />
                  )}
                  {index !== 0 && <Actions filter={filter} />}
                </div>
              </div>
            ))}
          </div>
          <AddFilter />
          <ClearFilter />
          {tableMetaData?.webhookURLPath && (
            <p className="pt-2 font-title text-xs">
              <span className="pr-1 font-bold">Note:</span>
              <span>
                Filter will not apply for webhook auto-run, use the enrichment
                level's conditional run feature <br /> to enable auto run for
                webhook based on specific conditions.
              </span>
            </p>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Filters;
