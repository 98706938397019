export const processingTypeLabelMap: { [key: string]: string } = {
  enrichment: "Enrichment",
  res_column: "Res Column",
  csv_import: "CSV Import",
  formula: "Formula",
  copy_table: "Copy Table",
  github_star_gazers: "Github Star Gazers",
  remove_duplicate_rows: "Remove Duplicate Rows",
  local_businesses: "Local Businesses",
  sales_nav_import: "Import using Sales Navigator URL",
  track_job_changes: "Track Job Changes",
};
