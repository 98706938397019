import CustomAccordion from "@/components/Common/CustomAccordion";
import { ProviderWaterfall } from "@/components/Common/ProviderWaterfall";
import { TProviderOption } from "@/components/Common/ProviderWaterfall/types";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import { useUserConfig } from "@/hooks/useUserConfig";
import { useEnrichStore } from "@/stores/enrich.store";
import { TSelectedColumnOption } from "@/types/common.types";
import { useEffect, useState } from "react";
import Description from "../../Common/Description";
import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import SelectTemplate from "../AskAQuestionAiAgent/SelectTemplate";
import { defaultProviders, providerOptions } from "./constant";

export const PhoneWaterfall = () => {
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const [userConfigQuery, mutateUserConfig] = useUserConfig(
    selectedEnrichments?.enrichmentName ?? null,
  );
  const [initialUserKeys, setInitialUserKeys] = useState<string[] | null>(null);
  const [linkedinUrl, setLinkedinUrl] = useState<TSelectedColumnOption | null>(
    null,
  );
  const [workEmail, setWorkEmail] = useState<TSelectedColumnOption | null>(
    null,
  );
  const [selectedProviders, setSelectedProviders] =
    useState<TProviderOption[]>(defaultProviders);
  useEffect(() => {
    if (selectedColumnToEdit && viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        .enrichmentMetaData as unknown as any;
      const linkedinUrl = enrichmentData?.payload?.linkedinUrl || null;
      const workEmail = enrichmentData?.payload?.workEmail || null;

      setLinkedinUrl(linkedinUrl);
      setWorkEmail(workEmail);

      if (enrichmentData?.providers?.length) {
        const providers = enrichmentData.providers
          .map((provider: string) =>
            providerOptions.find((p) => p.value === provider),
          )
          .filter(Boolean) as TProviderOption[];
        setSelectedProviders(providers);
      }

      if (enrichmentData?.ownKeyProviderList?.length) {
        const ownKeyProviders =
          enrichmentData.ownKeyProviderList.filter((provider: string) =>
            providerOptions.find((p) => p.apikeyParams.keyName === provider),
          ) || [];
        setInitialUserKeys(ownKeyProviders);
      }
    }
  }, [selectedColumnToEdit]);
  useEffect(() => {
    if (userConfigQuery.isLoading || !userConfigQuery.data) return;
    if (!selectedEnrichments?.enrichmentName || viewMode === "edit") return;
    const configProviderList: string[] | undefined =
      // @ts-ignore
      userConfigQuery.data?.data?.configs?.[selectedEnrichments?.enrichmentName]
        ?.selectedProviders;
    if (!configProviderList) return;
    const configProviders = configProviderList.map(
      (provider) => providerOptions.find((p) => p.value === provider)!,
    );

    setSelectedProviders(configProviders);
  }, [
    userConfigQuery.data ?? null,
    viewMode,
    selectedEnrichments?.enrichmentName ?? null,
  ]);
  return (
    <>
      <div className="grow space-y-4 overflow-y-auto p-4">
        <WatchTutorial
          subText={
            "Send or retrieve from any tool or database using a general API endpoint"
          }
          link=""
        />

        <SelectTemplate />
        <CustomAccordion
          title="Providers"
          titleClassName="font-title text-sm"
          isDefaultOpen
        >
          <ProviderWaterfall
            selectedProviders={selectedProviders}
            setSelectedProviders={setSelectedProviders}
            providerOptions={providerOptions}
            defaultProviders={defaultProviders}
            initialUserKeys={initialUserKeys}
          />
        </CustomAccordion>

        <CustomAccordion
          title="Setup Data"
          titleClassName="font-title text-sms"
          isDefaultOpen
        >
          <Description className="px-4 pb-4">
            Select the data you want to send to the providers.
          </Description>
          <div className="px-4 pb-4">
            <p className="pb-1 text-base font-semibold">Linkedin Url</p>
            <SelectColumnDropdown
              selectedOption={linkedinUrl}
              setSelectedOption={(
                linkedInUrl: TSelectedColumnOption | null,
              ) => {
                setLinkedinUrl(linkedInUrl);
              }}
            />

            <p className="mt-4 pb-1 text-base font-semibold">
              Work Email (optional)
            </p>
            <SelectColumnDropdown
              selectedOption={workEmail}
              setSelectedOption={(workEmail: TSelectedColumnOption | null) => {
                setWorkEmail(workEmail);
              }}
            />
          </div>
        </CustomAccordion>
      </div>
      <SaveAndRunEnrichment
        onClear={() => {
          mutateUserConfig.mutate({
            selectedProviders: selectedProviders.map(
              (provider) => provider.value,
            ),
          });
        }}
        isDisabled={!(selectedProviders.length && linkedinUrl)}
        apiPayload={{
          providers: selectedProviders.map((provider) => provider.value),
          payload: {
            linkedinUrl: linkedinUrl,
            workEmail,
          },
        }}
      />
    </>
  );
};

export default PhoneWaterfall;
