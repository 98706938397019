import Icons from "../Icons";

type ConfigStatusProps = {
  isConfigured: boolean;
  title: string;
};

const ConfigStatus = ({ isConfigured, title }: ConfigStatusProps) => {
  return (
    <div className="px-[12px] py-[8px] bg-[#FBFAFF] flex items-center gap-2 rounded-b-[8px]">
      {!isConfigured ? (
        <Icons.WarningIcon className="text-[#F58C50]" />
      ) : (
        <Icons.CheckCircleIcon className="text-[#078A52]" />
      )}

      <p className="text-[14px] text-[#7E889F]">{title}</p>
    </div>
  );
};

export default ConfigStatus;
