/* eslint-disable react-hooks/exhaustive-deps */
import {
  ColDef,
  RowClassRules,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import NoResult from "../Common/NoResult";
import ResultScreenLoader from "../Common/ResultScreenLoader";
import { CompanyDetailsRenderer } from "./CompanyDetailsRendere";
import { UserDetailsRenderer } from "./UserDetailsRenderer";
import { WorkEmailRenderer } from "./WorkEmailRenderer";

import { useSearchLeadsStore } from "@/components/PeopleSearch/store.ts";
import useSocket from "@/lib/socket";
import { userStore } from "@/stores/user.store";

import { ISocketSingleEmailData } from "@/types/socket.types";
import BeginSearch from "../Common/BeginSearch";
import CustomSelection from "./CustomSelection";
import Pagination from "./Pagination";

type PeopleSearchResultTableProps = {
  uniqueSocketId: string;
};

const PeopleSearchResultTable = ({
  uniqueSocketId,
}: PeopleSearchResultTableProps) => {
  const currentUser = userStore((state) => state.currentUser);
  const planType = currentUser.planType;
  console.log("myLog planType", planType);

  const gridRef = useRef<AgGridReact>(null);
  const [formattedProfilesData, setFormattedProfilesData] = useState<any[]>([]);
  const searchLeadsResult = useSearchLeadsStore(
    (state) => state.searchLeadsResult,
  );
  const updateState = useSearchLeadsStore((state) => state.updateState);
  const selectionAction = useSearchLeadsStore((state) => state.selectionAction);
  const selectionCount = useSearchLeadsStore((state) => state.selectionCount);
  const isSearching = useSearchLeadsStore((state) => state.isSearching);
  const { subscribe } = useSocket();

  const colDefs = useMemo<ColDef[]>(
    () => [
      {
        headerName: "",
        checkboxSelection: true,
        pinned: "left",
        headerCheckboxSelection: false,
        width: 80,
        headerComponent: CustomSelection,
      },
      {
        field: "name",
        headerName: "Name",
        cellRenderer: UserDetailsRenderer,
        pinned: true,
        minWidth: 250,
      },
      {
        field: "title",
        headerName: "Title",
        minWidth: 200,
      },
      {
        field: "workEmail",
        headerName: "Work Email",
        cellRenderer: WorkEmailRenderer,
        minWidth: 200,
      },
      {
        field: "companyName",
        headerName: "Company",
        cellRenderer: CompanyDetailsRenderer,
        minWidth: 200,
      },
      {
        field: "location",
        headerName: "Location",
        minWidth: 250,
      },
    ],
    [],
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      cellStyle: {
        borderRight: "1px solid #e2e8f0",
        display: "flex",
        alignItems: "center",
      },
      editable: false,
      cellDataType: false,
    };
  }, []);

  const rowClassRules: RowClassRules =
    planType?.toLocaleLowerCase() === "free"
      ? {
          "blurred-row": (params) => params.rowIndex > 24, // Apply class to rows after index 24
        }
      : {};

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    updateState({ selectedLeads: selectedRows });
  }, []);

  const formatData = (profiles: any) => {
    const formattedData = profiles?.map((item: any) => ({
      _id: item._id || "",
      id: item.id || "",
      name: item?.name || "",
      linkedInUrl: item?.linkedin_url || "",
      title: item?.title || "",
      companyName: item?.organization?.name || "",
      companyWebsite: item?.organization?.website_url || "",
      location:
        `${item?.state ? `${item?.state} ,` : ""} ${item?.country || ""}` || "",
      companyLinkedInURL: item?.organization?.linkedin_url || "",
      employmentHistory:
        item?.employment_history?.length > 0
          ? `Worked With ${item?.employment_history?.length} Companies`
          : "",
      profilePhoto: item?.photo_url || "",
      headline: item?.headline || "",
      workEmail: item.workEmail || "",
      quickAction: { ...item, uniqueSocketId },
    }));
    setFormattedProfilesData(formattedData);
  };

  useEffect(() => {
    if (searchLeadsResult && searchLeadsResult?.profiles?.length > 0) {
      formatData(searchLeadsResult?.profiles);
    }
  }, [searchLeadsResult]);

  useEffect(() => {
    const maxSelectableRows =
      planType?.toLocaleLowerCase() === "free" ? 25 : Infinity;

    gridRef.current?.api?.forEachNode((row) => {
      gridRef.current?.api.getRowNode(row.id!)?.setSelected(false);
    });

    if (selectionAction === "select-all") {
      if (planType?.toLocaleLowerCase() === "free") {
        gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
          if (index < maxSelectableRows) {
            gridRef.current?.api.getRowNode(row.id!)?.setSelected(true);
          }
        });
      } else {
        gridRef.current?.api?.forEachNode((row) => {
          gridRef.current?.api.getRowNode(row.id!)?.setSelected(true);
        });
      }
    } else if (selectionAction === "clear-all") {
      gridRef.current?.api?.forEachNode((row) => {
        gridRef.current?.api.getRowNode(row.id!)?.setSelected(false);
      });
      updateState({
        selectionCount: 0,
      });
    } else if (selectionAction === "select-page") {
      const currentPage = gridRef.current?.api.paginationGetCurrentPage() || 0;
      const pageSize = gridRef.current?.api.paginationGetPageSize() || 100;
      const startRow = currentPage * pageSize;
      const endRow = startRow + pageSize;

      if (planType?.toLocaleLowerCase() === "free") {
        gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
          if (
            index >= startRow &&
            index < endRow &&
            index < maxSelectableRows
          ) {
            gridRef.current?.api.getRowNode(row.id!)?.setSelected(true);
          }
        });
      } else {
        gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
          if (index >= startRow && index < endRow) {
            gridRef.current?.api.getRowNode(row.id!)?.setSelected(true);
          }
        });
      }
    } else if (selectionAction === "range-selection") {
      const currentPage = gridRef.current?.api.paginationGetCurrentPage() || 0;
      const pageSize = gridRef.current?.api.paginationGetPageSize() || 100;
      const startRow = currentPage * pageSize;
      const endRow = startRow + pageSize;

      const rowsToSelect =
        planType?.toLocaleLowerCase() === "free"
          ? Math.min(selectionCount, maxSelectableRows)
          : Math.min(selectionCount, pageSize);
      let selectedCount = 0;

      gridRef.current?.api?.forEachNodeAfterFilterAndSort((row, index) => {
        if (
          index >= startRow &&
          index < endRow &&
          selectedCount < rowsToSelect
        ) {
          gridRef.current?.api.getRowNode(row.id!)?.setSelected(true);
          selectedCount++;
        }
      });
    }
  }, [selectionAction, selectionCount, planType]);

  useEffect(() => {
    subscribe(uniqueSocketId, (data: ISocketSingleEmailData) => {
      const rowId = data?.bodyData?.payload?.rowId || "";
      if (!rowId) return;

      const rowData = formattedProfilesData.find(
        (profileData) => profileData.id === rowId,
      );
      if (rowData) {
        rowData.workEmail = data.response.email;

        const workEmailCol = gridRef.current?.api?.getColumn("workEmail");
        const currentWidth = workEmailCol?.getActualWidth() || 200;
        const newWidth = Math.max(
          currentWidth,
          data.response.email.length * 10 + 80,
        );
        workEmailCol?.setActualWidth(newWidth, "uiColumnResized");

        gridRef.current?.api?.applyTransactionAsync({
          update: [rowData],
        });
        gridRef.current?.api?.resetColumnState();
      }
    });
  }, [uniqueSocketId, formattedProfilesData, subscribe]);

  if (isSearching) {
    return <ResultScreenLoader />;
  }

  if (!searchLeadsResult?.profiles) {
    return <BeginSearch />;
  }

  if (searchLeadsResult?.profiles?.length === 0) {
    return <NoResult />;
  }

  return (
    <div className="flex size-full flex-col justify-between">
      <div
        className="ag-theme-quartz mt-4"
        style={{ width: "100%", height: "75vh" }}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={colDefs}
          rowData={formattedProfilesData}
          defaultColDef={defaultColDef}
          rowClassRules={rowClassRules}
          headerHeight={40}
          rowHeight={70}
          rowClass="border-1 border-gray-200"
          rowSelection="multiple"
          rowMultiSelectWithClick
          suppressColumnMoveAnimation
          suppressRowClickSelection
          onSelectionChanged={onSelectionChanged}
          pagination={false}
          autoSizeStrategy={{
            type: "fitCellContents",
          }}
        />
        <Pagination
          paginationData={searchLeadsResult.pagination}
          className="mt-3"
        />
      </div>
    </div>
  );
};

export default PeopleSearchResultTable;
