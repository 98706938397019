import AutoPilotLandingPage from "@/pages/AutoPilotLandingPage";
import { FileRoute } from "@tanstack/react-router";

export const Route = new FileRoute("/auto-pilot/").createRoute({
  component: AutoPilotPage,
  errorComponent: () => <div>Error</div>,
});

function AutoPilotPage() {
  if (import.meta.env.VITE_NODE_ENV !== "development") {
    return <div>Not Found</div>;
  }
  return <AutoPilotLandingPage />;
}

export default AutoPilotPage;
