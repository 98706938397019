import { ButtonGroup } from "@chakra-ui/react";
import { FC } from "react";

import { useTableStore } from "@/stores/table.store";
import { TableViewItem } from "./ViewItem";

const TableViews: FC = () => {
  const tableData = useTableStore((state) => state.tableData);
  const tableViews = tableData?.views;
  if (!tableViews) return null;
  return (
    <ButtonGroup
      variant={"ghost"}
      isAttached
      spacing={4}
      p={2}
      size={"sm"}
      className="max-w-[50vw]"
      overflow={"auto"}
    >
      {tableViews.map((view, index) => (
        <TableViewItem key={index} view={view} />
      ))}
    </ButtonGroup>
  );
};

export default TableViews;
