const getCellElementByContent = (element: HTMLElement): HTMLElement | null => {
  if (!element) return null;
  while (element && !element.classList?.contains("ag-cell")) {
    element = (element.parentElement as HTMLElement) || null;
  }
  return element;
};
export const getCellId = (element: HTMLElement) => {
  const cellElement = getCellElementByContent(element);
  if (!cellElement) return null;
  return {
    colId: cellElement.getAttribute("col-id"),
    rowIndex: cellElement.parentElement?.getAttribute("row-index"),
  };
};
