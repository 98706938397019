import React from "react";
import ConfigStatus from "./ConfigStatus";

interface CardProps {
  rightIcon?: string;
  title: string;
  subtitle: string;
  isConfigured: boolean;
  isDisabled: boolean;
  configStatus: string;
  imgSrc: string;
}

const CustomNodeCard: React.FC<CardProps> = ({
  rightIcon,
  title,
  subtitle,
  isDisabled,
  isConfigured,
  configStatus,
  imgSrc,
}) => {
  return (
    <div
      className={`border border-[#D0D4DC] w-[400px] text-center rounded-[8px] ${isDisabled && "opacity-50 pointer-events-none"}`}
    >
      <div className="flex items-center justify-between py-[12px] px-[16px]">
        <div className="flex items-center gap-5 text-center">
          <img src={imgSrc} alt="" className="w-[40px] h-[40px]" />
          <div>
            <p className="flex-grow truncate text-[16px] font-bold">{title}</p>
            <p className="text-[14px] font-medium text-[#7E889F]">{subtitle}</p>
          </div>
        </div>
        {rightIcon && (
          <img src={rightIcon} alt="" className="w-[24px] h-[24px]" />
        )}
      </div>
      <ConfigStatus
        isConfigured={isConfigured}
        title={isConfigured ? configStatus : "Not configured"}
      />
    </div>
  );
};

export default CustomNodeCard;
