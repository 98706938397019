import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";

import ExportImg from "@/assets/svgs/persana-magic-export.svg";
import { useSearchLeadsStore } from "./store";
import { userStore } from "@/stores/user.store";

type Props = {
  isShowExportModal: boolean;
  handleClose: () => void;
  handleExport: () => void;
  selectedLeadsCount: number;
  isLoading: boolean;
};

const ExportDataModal = ({
  handleClose,
  handleExport,
  isShowExportModal,
  isLoading,
}: Props) => {
  const currentUser = userStore((state) => state.currentUser);
  const planType = currentUser.planType;
  const selectedLeads = useSearchLeadsStore((state) => state.selectedLeads);
  const selectionCount = useSearchLeadsStore((state) => state.selectionCount);
  const selectionAction = useSearchLeadsStore((state) => state.selectionAction);
  const searchLeadsResult = useSearchLeadsStore(
    (state) => state.searchLeadsResult,
  );

  const getTotalCredits = () => {
    if (selectionAction === "select-all") {
      const maxLeads = planType?.toLocaleLowerCase() === "free" ? 25 : 1000;
      return Math.min(
        searchLeadsResult?.pagination?.total_entries ?? 0,
        maxLeads,
      );
    }

    if (
      selectionAction === "range-selection" &&
      planType?.toLocaleLowerCase() !== "free"
    ) {
      return selectionCount;
    }

    return selectedLeads?.length || selectionCount;
  };

  return (
    <Modal isOpen={!!isShowExportModal} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex flex-col items-center gap-2">
            <img src={ExportImg} alt="" />
            <p className="mt-[-60px] text-[20px] text-black">
              Export Data & Find Work Email
            </p>
            <p className="text-[14px] text-[#373F51]">
              You're using:{" "}
              <span className="font-medium">{`${getTotalCredits()} credits`}</span>
            </p>
          </div>
        </ModalBody>

        <ModalFooter>
          <div className="w-full">
            <Button
              isLoading={isLoading}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              className={`!w-full !rounded-[6px] !bg-[#5024DC] !px-4 !py-[15px] !font-title !text-sm !font-semibold !tracking-wide !text-white`}
              onClick={() => {
                handleExport();
              }}
            >
              Export
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExportDataModal;
