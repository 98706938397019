import { create } from "zustand";
import { mountStoreDevtool } from "simple-zustand-devtools";

import { ModalType } from "@/types/modal.types";
import { Team } from "@/types/team.types";

export interface ModalDataType {
  modalType?: ModalType;
  metaData?: any;
  starterTableId?: string;
  [key: string]: any;
}

type State = {
  isOpenModal: boolean;
  modalData: ModalDataType;
  selectedValues?: any;

  ownedWorkspaces?: Team[];
  addTeamMembersModal: {
    isOpen: boolean;
    isCreateWorkspace?: boolean;
    isForShare: boolean;
    sharedWith?: string[];
  };
};

type Actions = {
  updateState: (payload: Partial<State>) => void;
};

export const useModalStore = create<State & Actions>((set) => ({
  isOpenModal: false,
  modalData: {},
  selectedValues: null,

  ownedWorkspaces: [],
  addTeamMembersModal: {
    isOpen: false,
    isForShare: false,
  },

  updateState: (payload) => set(() => payload),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Modals", useModalStore);
}
