import { useEnrichStore } from "@/stores/enrich.store";
import { addNewColumn } from "@/utils";
import Icons from "../Icons";
import { tableActionIcons } from "../Icons/tableActionIcons";

export type CallBackFn = (
  name:
    | "DELETE"
    | "INSERT_LEFT"
    | "INSERT_RIGHT"
    | "ASC"
    | "DESC"
    | "FILTER"
    | "DUPLICATE_ROWS"
    | "PIN"
    | "EDIT",
) => void;

export const headOptions = [
  {
    name: "Edit Enrichment",
    icon: <Icons.SettingsIcon className="h-auto w-4 text-[#000000]" />,
    onClick: (callBackFn: CallBackFn) => {
      callBackFn("EDIT");
    },
    params: ["item"],
    type: "onlyForEnrichment",
  },
  {
    name: "Insert Left",
    icon: <Icons.LeftArrowIcon className="h-auto w-4 text-[#000000]" />,
    onClick: (callBackFn: CallBackFn) => {
      callBackFn("INSERT_LEFT");
    },
    params: ["index"],
    isHidden: true,
  },
  {
    name: "Insert Right",
    icon: <Icons.RightArrowIcon className="h-auto w-4 text-[#000000]" />,
    onClick: (callBackFn: CallBackFn) => {
      callBackFn("INSERT_RIGHT");
    },
    params: ["index"],
    isHidden: true,
  },
  {
    type: "divider",
  },
  {
    name: (
      <span className="inline-flex items-center gap-x-1.5">
        Sort A <Icons.RightArrowIcon /> Z
      </span>
    ),
    icon: <Icons.SortAscIcon className="h-auto w-4 text-[#000000]" />,
    onClick: (callBackFn: CallBackFn) => {
      callBackFn("ASC");
    },
    params: ["item", "ASC"],
    menuType: "-",
  },
  {
    name: (
      <span className="inline-flex items-center gap-x-1.5">
        Sort Z <Icons.RightArrowIcon /> A
      </span>
    ),
    icon: <Icons.SortDescIcon className="h-auto w-4 text-[#000000]" />,
    onClick: (callBackFn: CallBackFn) => {
      callBackFn("DESC");
    },
    params: ["item", "DESC"],
  },
  {
    type: "divider",
  },
  {
    name: "Filter on this column",
    icon: <Icons.FilterIcon className="h-auto w-4 text-[#000000]" />,
    onClick: (callBackFn: CallBackFn) => {
      callBackFn("FILTER");
    },
    params: ["item"],
  },
  {
    type: "divider",
  },
  {
    name: "Pin",
    icon: <Icons.PinIcon className="h-auto w-4 text-[#000000]" />,
    onClick: (callBackFn: CallBackFn) => {
      callBackFn("PIN");
    },
    params: ["item"],
  },
  {
    type: "divider",
  },
  {
    type: "divider",
  },
  {
    name: "Remove Duplicate Rows",
    icon: <Icons.MergeIcon />,
    onClick: (callBackFn: CallBackFn) => {
      callBackFn("DUPLICATE_ROWS");
    },
  },
  {
    type: "divider",
  },
  {
    isDanger: true,
    name: "Delete",
    icon: <Icons.DeleteIcon />,
    onClick: (callBackFn: CallBackFn) => {
      callBackFn("DELETE");
    },
  },
];

const TextIcon = tableActionIcons.text;

export const addColumnOptions = [
  {
    name: "Text",
    icon: <TextIcon className="h-[16px]" />,
    onClick: async () => {
      await addNewColumn({
        columnName: "New Column",
      });
    },
  },
  {
    name: "Formula",
    icon: <Icons.FunctionIcon className="ml-[3px] h-auto w-4 text-[#000000]" />,
    onClick: () => {
      useEnrichStore.getState().updateState({
        generateFormulaModal: {
          isOpen: true,
          type: "formula",
        },
      });
    },
  },
  {
    name: "Enrich",
    icon: <Icons.LayersIcon className="ml-[3px] h-auto w-4 text-[#000000]" />,
    onClick: () => {
      useEnrichStore.getState().updateState({
        isOpenEnrichModal: true,
        selectedEnrichments: null,
      });
    },
  },
];

export const processingTypeLabelMap: { [key: string]: string } = {
  enrichment: "Enrichment",
  res_column: "Res Column",
  csv_import: "CSV Import",
  formula: "Formula",
  copy_table: "Copy Table",
  github_star_gazers: "Github Star Gazers",
  remove_duplicate_rows: "Remove Duplicate Rows",
  local_businesses: "Local Businesses",
  sales_nav_import: "Import using Sales Navigator URL",
};
