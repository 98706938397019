import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CellPosition, Column, IRowNode } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";

import GmailIntegration from "../Integrations/GmailIntegration";
import DraftEmailDetails from "./DraftEmailDetails";

import { useProviderList } from "@/hooks/useProviderData";
import enrichment from "@/services/enrichment.service";
import tableService from "@/services/table.service";
import { useTableStore } from "@/stores/table.store";
import { RowUpdatePayload } from "@/types/table.types";
import Icons from "../Icons";

interface Props {
  gridRef: React.RefObject<AgGridReact>;
}

function CellExpendViewModal({ gridRef }: Props) {
  const { data: providerList } = useProviderList();
  const gmailProvider = providerList?.data?.find(
    (provider) => provider.name === "gmail",
  );
  const [currentlySelectedCell, setCurrentlySelectedCell] =
    useState<CellPosition | null>(null);
  const [cellValueUpdated, setCellValueUpdated] = useState<boolean>(false);
  const [localEmailDetails, setLocalEmailDetails] = useState({
    sendTo: "",
    subject: "",
    body: "",
  });

  const { expendedCellValue, tableData, rowsData, updateState } =
    useTableStore();
  const toast = useToast();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const cellValueType = typeof expendedCellValue.cellValue;
  const tableId = useTableStore((state) => state.tableData._id);
  const updateRowsData = useTableStore((state) => state.updateRowsData);

  const expandedColumnDetails = tableData?.columns?.filter(
    (item) => item._id === expendedCellValue.columnId,
  );
  const uniqueIdentifier =
    expandedColumnDetails?.[0]?.metaData?.enrichmentMetaData?.uniqueIdentifier;
  const emailDetails = tableData?.columns?.filter(
    (item) =>
      item?.metaData?.enrichmentMetaData?.uniqueIdentifier === uniqueIdentifier,
  );
  const expandedRowsData = rowsData?.filter(
    (item) => item._id === expendedCellValue.rowId,
  )?.[0];
  const selectedEmailKeys = emailDetails.map((item) => item._id);

  const getColumnIdentifier = (key: string) => {
    return (
      emailDetails.find((item) => item._id === key)?.metaData
        ?.enrichmentMetaData?.columnIdentifier || ""
    );
  };

  const matchingRecords = [] as any;
  for (const key in expandedRowsData.cells) {
    if (selectedEmailKeys.includes(key))
      matchingRecords.push({
        ...expandedRowsData.cells[key],
        columnId: key,
        columnIdentifier: getColumnIdentifier(key),
      });
  }

  const quaryClient = useQueryClient();
  const { mutateAsync, isPending: isUpdating } = useMutation({
    mutationFn: ({
      tableId,
      payload,
      rowId,
    }: {
      tableId: string;
      payload: RowUpdatePayload;
      rowId: string;
    }) =>
      tableService.updateRowData({
        tableId,
        payload,
        rowId,
      }),
  });

  const { mutateAsync: sendEmail, isPending } = useMutation({
    mutationFn: () =>
      enrichment.sendEmail({
        rowId: expendedCellValue.rowId,
        tableId: tableId,
        columnId: expendedCellValue.columnId,
      }),
    onSuccess: (response) => {
      if (response.success) {
        toast({
          title: "Success",
          description: "Successfully sent an email",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: response.error.message || "Something went wrong",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error?.message || "Something went wrong",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    },
  });

  const gridApi = gridRef?.current?.api;

  const updateTableAndDB = async () => {
    if (cellValueUpdated) {
      const updatedValue = expendedCellValue;

      if (!updatedValue?.cellValue?.trim()) return;

      const rowNode = gridRef?.current!.api?.getRowNode(updatedValue.rowId);
      if (rowNode) {
        const rowData = rowNode?.data || {};
        rowData[updatedValue.columnId] = updatedValue.cellValue;
        gridRef.current?.api?.applyTransaction({ update: [rowData] });
      }
      let value = updatedValue.cellValue;
      if (/^\d+$/.test(value)) {
        // @ts-ignore
        value = Number(value);
      }

      await mutateAsync(
        {
          tableId: tableData._id,
          payload: {
            columnId: updatedValue.columnId,
            cellValue: {
              value,
            },
          },
          rowId: updatedValue.rowId,
        },
        {
          onSuccess: (response) => {
            if (!response.success) {
              toast({
                title: "Error",
                description: response.error?.message || "Something went wrong",
                status: "error",
                isClosable: true,
                duration: 2000,
                position: "top-right",
              });
              return;
            }

            // @ts-ignore
            if (response?.data?.dependentColumnsIds?.length) {
              quaryClient.refetchQueries({
                queryKey: ["table-running-processes", tableId],
              });
            }
            if (response.data) {
              updateRowsData([response.data.res]);
            }

            toast({
              title: "Success",
              description: `Cell data updated successfully`,
              status: "success",
              isClosable: true,
              duration: 2000,
              position: "top-right",
            });
          },
          onError: (error) => {
            toast({
              title: "Error",
              description: error?.message || "Something went wrong",
              status: "error",
              isClosable: true,
              duration: 2000,
              position: "top-right",
            });
          },
        },
      );
    }
  };

  const onClose = async () => {
    await updateTableAndDB();

    updateState({
      expendedCellValue: {
        isOpen: false,
        cellValue: "",
        columnName: "",
        columnId: "",
        rowId: "",
      },
    });

    setCellValueUpdated(false);
    if (currentlySelectedCell && gridApi) {
      gridApi.setFocusedCell(
        currentlySelectedCell.rowIndex,
        currentlySelectedCell.column,
      );
    }
  };

  const updateCellValue = (rowIndex: number, column: Column) => {
    gridApi?.setFocusedCell(rowIndex, column);
    const newSelectedCell = gridApi?.getFocusedCell();
    if (gridApi && newSelectedCell) {
      setCurrentlySelectedCell(newSelectedCell);
      const rowNode = gridApi.getDisplayedRowAtIndex(
        newSelectedCell.rowIndex,
      ) as IRowNode;
      const newValue = gridApi?.getValue(newSelectedCell.column, rowNode);

      updateState({
        expendedCellValue: {
          ...expendedCellValue,
          cellValue: newValue,
          columnName: newSelectedCell?.column?.getColDef()?.headerName || "",
          columnId: column.getColId(),
          rowId: rowNode.data.rowData._id,
          rowIndex,
        },
      });
    }
  };

  const getMatchingColumn = (identifier: string) => {
    return matchingRecords.find(
      (item: any) => item.columnIdentifier === identifier,
    );
  };

  const handleSendEmail = async () => {
    const sendTo = await getMatchingColumn("sendTo");
    const subject = await getMatchingColumn("subject");
    const body = await getMatchingColumn("emailBody");
    if (sendTo?.value !== localEmailDetails.sendTo) {
      await mutateAsync(
        {
          tableId: tableData._id,
          payload: {
            columnId: sendTo?.columnId || "",
            cellValue: {
              value: localEmailDetails.sendTo,
            },
          },
          rowId: expendedCellValue.rowId,
        },
        {
          onSuccess: (response) => {
            if (response.success) {
              toast({
                title: "Success",
                description: `Cell data updated successfully`,
                status: "success",
                isClosable: true,
                duration: 2000,
                position: "top-right",
              });
              if (response.data) {
                updateRowsData([response.data]);
              }
            } else {
              toast({
                title: "Error",
                description: response.error?.message || "Something went wrong",
                status: "error",
                isClosable: true,
                duration: 2000,
                position: "top-right",
              });
            }
          },
          onError: (error) => {
            toast({
              title: "Error",
              description: error?.message || "Something went wrong",
              status: "error",
              isClosable: true,
              duration: 2000,
              position: "top-right",
            });
          },
        },
      );
    }

    if (subject?.value !== localEmailDetails.subject) {
      await mutateAsync(
        {
          tableId: tableData._id,
          payload: {
            columnId: subject?.columnId || "",
            cellValue: {
              value: localEmailDetails.subject,
            },
          },
          rowId: expendedCellValue.rowId,
        },
        {
          onSuccess: (response) => {
            if (response.success) {
              toast({
                title: "Success",
                description: `Cell data updated successfully`,
                status: "success",
                isClosable: true,
                duration: 2000,
                position: "top-right",
              });
              if (response.data) {
                updateRowsData([response.data]);
              }
            } else {
              toast({
                title: "Error",
                description: response.error?.message || "Something went wrong",
                status: "error",
                isClosable: true,
                duration: 2000,
                position: "top-right",
              });
            }
          },
          onError: (error) => {
            toast({
              title: "Error",
              description: error?.message || "Something went wrong",
              status: "error",
              isClosable: true,
              duration: 2000,
              position: "top-right",
            });
          },
        },
      );
    }

    if (body?.value !== localEmailDetails.body) {
      await mutateAsync(
        {
          tableId: tableData._id,
          payload: {
            columnId: body?.columnId || "",
            cellValue: {
              value: localEmailDetails.body,
            },
          },
          rowId: expendedCellValue.rowId,
        },
        {
          onSuccess: (response) => {
            if (response.success) {
              toast({
                title: "Success",
                description: `Cell data updated successfully`,
                status: "success",
                isClosable: true,
                duration: 2000,
                position: "top-right",
              });
              if (response.data) {
                updateRowsData([response.data]);
              }
            } else {
              toast({
                title: "Error",
                description: response.error?.message || "Something went wrong",
                status: "error",
                isClosable: true,
                duration: 2000,
                position: "top-right",
              });
            }
          },
          onError: (error) => {
            toast({
              title: "Error",
              description: error?.message || "Something went wrong",
              status: "error",
              isClosable: true,
              duration: 2000,
              position: "top-right",
            });
          },
        },
      );
    }

    sendEmail();
  };

  const handleArrowUp = async () => {
    const selectedCell = gridApi?.getFocusedCell();

    console.log("selectedCell", selectedCell);

    if (selectedCell && gridApi) {
      const { rowIndex, column } = selectedCell;

      if (rowIndex > 0) {
        updateCellValue(rowIndex - 1, column);
      }
    }

    await updateTableAndDB();
    setCellValueUpdated(false);
  };

  const handleArrowDown = async () => {
    const selectedCell = gridApi?.getFocusedCell();

    if (selectedCell && gridApi) {
      const { rowIndex, column } = selectedCell;
      if (rowIndex < gridApi.getLastDisplayedRow()) {
        updateCellValue(rowIndex + 1, column);
      }
    }

    await updateTableAndDB();
    setCellValueUpdated(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowUp") {
      handleArrowUp();
    } else if (e.key === "ArrowDown") {
      console.log("ArrowDown");
      handleArrowDown();
    }
  };

  expendedCellValue.cellValue =
    cellValueType === "object"
      ? JSON.stringify(expendedCellValue.cellValue, null, 2)
      : expendedCellValue.cellValue || "";

  const updateLocalDetails = async () => {
    const sendTo = (await getMatchingColumn("sendTo")?.value) || "";
    const subject = (await getMatchingColumn("subject")?.value) || "";
    const body = (await getMatchingColumn("emailBody")?.value) || "";
    setLocalEmailDetails({
      sendTo,
      subject,
      body,
    });
  };

  useEffect(() => {
    updateLocalDetails();
  }, [expendedCellValue]);

  useEffect(() => {
    if (expendedCellValue.isOpen) {
      inputRef?.current?.focus();
      inputRef?.current?.setSelectionRange(
        inputRef?.current?.value?.length,
        inputRef?.current?.value?.length,
      );
    }
  }, [inputRef?.current]);

  return (
    <>
      <Modal
        isOpen={expendedCellValue?.isOpen}
        onClose={onClose}
        isCentered
        size={"2xl"}
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent onKeyDown={handleKeyDown}>
          <ModalHeader className="text-center">
            {expendedCellValue.columnName}
          </ModalHeader>
          <ModalBody>
            {expendedCellValue.showSendOption ? (
              <DraftEmailDetails
                localEmailDetails={localEmailDetails}
                setLocalEmailDetails={setLocalEmailDetails}
              />
            ) : (
              <Textarea
                ref={inputRef}
                value={expendedCellValue.cellValue}
                onChange={(e) => {
                  setCellValueUpdated(true);
                  updateState({
                    expendedCellValue: {
                      ...expendedCellValue,
                      cellValue: e.target.value,
                    },
                  });
                }}
                resize="none"
                rows={15}
              />
            )}
          </ModalBody>
          <ModalFooter className="flex items-center !justify-start gap-3">
            <div
              className="flex cursor-pointer items-center gap-1"
              onClick={handleArrowUp}
            >
              <span className="flex size-4 items-center justify-center rounded-full bg-primary">
                <Icons.ThickArrowDown className=" rotate-180 text-xs text-white" />
              </span>
              <span className="text-xs font-medium text-[#676d7e]">
                Previous Cell
              </span>
            </div>
            <div
              className="flex cursor-pointer items-center gap-1"
              onClick={handleArrowDown}
            >
              <span className="flex size-4 items-center justify-center rounded-full bg-primary">
                <Icons.ThickArrowDown className=" text-xs text-white" />
              </span>
              <span className="text-xs font-medium text-[#676d7e]">
                Next Cell
              </span>
            </div>
            <div
              className="flex cursor-pointer items-center gap-1"
              onClick={onClose}
            >
              <span className="flex w-fit items-center justify-center rounded bg-primary px-1 py-0.5 text-xs font-medium text-white">
                Esc
              </span>
              <span className="text-xs font-medium text-[#676d7e]">
                Close Modal
              </span>
            </div>
            {isUpdating && <Spinner />}
            {expendedCellValue.showSendOption && (
              <>
                <Button
                  isLoading={isPending}
                  isDisabled={!expendedCellValue.cellValue || !gmailProvider}
                  size={"sm"}
                  colorScheme="primary"
                  variant="outline"
                  className="h-[40px] rounded-md !border-none !bg-light !text-textPurple"
                  onClick={handleSendEmail}
                >
                  Send Email
                </Button>
                <GmailIntegration isFromModal />
              </>
            )}
            <div className="flex flex-1 justify-end">
              <p className="text-sm font-medium text-[#676d7e]">
                Row {(expendedCellValue?.rowIndex || 0) + 1} of{" "}
                {gridApi?.getDisplayedRowCount()}
              </p>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CellExpendViewModal;
