import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Switch,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";
import { useMutation } from "@tanstack/react-query";

import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import FindCompanyTechnologies from "@/components/FindCompanyTechnologies";
import FindJobs from "@/components/FindJobs";
import { HiringJobs } from "@/components/HiringJobs";
import { JobChanges } from "@/components/JobChanges";
import { PayWall } from "@/components/Paywall";

import SlackLogo from "@/assets/images/slack-logo.png";
import CompanyHiring from "@/assets/svgs/company-hiring.svg";
import ImportDataIcon from "@/assets/svgs/download.svg";
import HiringTrend from "@/assets/svgs/hiring-trend.svg";
import JobChange from "@/assets/svgs/job-change.svg";
import Technographics from "@/assets/svgs/technographic.svg";
import providerService from "@/services/providers.service";
import userService from "@/services/user.service";

import { Option } from "@/components/Common/MultiSelectDropdown/types";
import Icons from "@/components/Icons";
import SyncHubspot from "@/components/JobChanges/syncLeads/SyncHubspot";
import { useProviderList } from "@/hooks/useProviderData";
import { userStore } from "@/stores/user.store";
import { SlackChannelType } from "@/types/slack.types";
import accountMonitoringService from "@/services/accountMonitoring.service";
import PrimaryLoader from "@/components/Common/PrimaryLoader";

interface ITabState {
  active: number;
  tabs: {
    [index: string]: {
      loading: boolean;
      handlers: {
        [name: string]: () => void;
      };
      metadata: {
        [key: string]: any;
      };
    };
  };
  setActiveTab: (activeTab: number) => void;
  setLoading: (tab: string | number, loading: boolean) => void;
  setHandlers: (
    tab: string | number,
    handlers: { [name: string]: () => void },
  ) => void;
  setMetadata: (tab: string | number, metadata: { [key: string]: any }) => void;
  getCurrentMetadata: () => { [key: string]: any };
  getCurrentLoading: () => boolean;
  getCurrentHandlers: () => { [name: string]: () => void };
}
export const useTabState = create<ITabState>((set, get) => ({
  active: 0,
  tabs: {},
  setActiveTab: (activeTab) => set({ active: activeTab }),
  setLoading: (tab, loading) =>
    set((state) => ({
      ...state,
      tabs: { ...state.tabs, [tab]: { ...state.tabs[tab], loading } },
    })),
  setHandlers: (tab, handlers) =>
    set((state) => ({
      ...state,
      tabs: { ...state.tabs, [tab]: { ...state.tabs[tab], handlers } },
    })),
  getCurrentHandlers: () => {
    const activeTab = get().active;
    return get().tabs[activeTab]?.handlers || {};
  },
  getCurrentLoading: () => {
    const activeTab = get().active;
    return get().tabs[activeTab]?.loading || false;
  },
  getCurrentMetadata: () => {
    const activeTab = get().active;
    return get().tabs[activeTab]?.metadata || {};
  },
  setMetadata: (tab, metadata) =>
    set((state) => ({
      ...state,
      tabs: { ...state.tabs, [tab]: { ...state.tabs[tab], metadata } },
    })),
}));
const tabs = [
  {
    name: "Job Change Tracker",
    component: JobChanges,
  },
  {
    name: "Find Companies Hiring",
    component: HiringJobs,
  },
  {
    name: "Track Companies Hiring",
    component: FindJobs,
  },
  {
    name: "Track Company Technologies",
    component: FindCompanyTechnologies,
  },
];
const AccountMonitoring = () => {
  const [isHaveAccess, setIsHaveAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { isOpen, onClose: _onClose } = useDisclosure({
    defaultIsOpen: !isModalViewedInThisSession("Account Monitoring"),
  });
  const onClose = () => {
    setModalViewed("Account Monitoring");
    _onClose();
  };
  const activeTab = useTabState((state) => state.active);
  const setActiveTab = useTabState((state) => state.setActiveTab);
  const creditDetails = userStore((state) => state.creditDetails);

  const { mutateAsync } = useMutation({
    mutationFn: () => accountMonitoringService.haveAccess(),
    onSuccess: (response) => {
      setIsHaveAccess(response.success);
      setIsLoading(false);
    },
    onError: () => {
      setIsHaveAccess(false);
      setIsLoading(false);
    },
  });

  const isAllowToAccess = ["paid", "pro", "persana_pro"].includes(
    creditDetails?.planType?.toLowerCase(),
  );

  const monitoringDetails = [
    {
      title: "Job Change Tracker",
      icon: () => (
        <img src={JobChange} className="text-white" width={24} height={24} />
      ),
      content: "Monitor career changes among your clients.",
    },
    {
      title: "Track Companies Hiring",
      icon: () => (
        <img src={HiringTrend} className="text-white" width={24} height={24} />
      ),
      content: "Track a list of companies for hiring",
    },
    {
      title: "Find Companies Hiring",
      icon: () => (
        <img
          src={CompanyHiring}
          className="text-white"
          width={24}
          height={24}
        />
      ),
      content: "Spot organizations actively hiring for relevant roles.",
    },
    {
      title: "Track Company Technologies",
      icon: () => (
        <img
          src={Technographics}
          className="text-white"
          width={24}
          height={24}
        />
      ),
      content: "Get insights into your prospects' tech landscape.",
    },
  ];

  const checkAccess = async () => {
    await mutateAsync();
  };

  useEffect(() => {
    checkAccess();
  }, []);

  if (isLoading)
    return (
      <div className="flex size-full grow items-center justify-center">
        <PrimaryLoader />
      </div>
    );

  if (!isAllowToAccess && !isHaveAccess) return <PayWall />;
  return (
    <>
      <section>
        <Tabs index={activeTab} onChange={setActiveTab}>
          <AISignalPageHeader />
          <div>
            <TabList
              gap={"32px"}
              px={"32px"}
              bg="#F5F3FF"
              h={"64px"}
              borderBottom={"none"}
            >
              {tabs.map((tab) => (
                <Tab
                  color="#373F51"
                  _selected={{ color: "#5024DC" }}
                  fontSize={"16px"}
                  fontWeight={"500"}
                  key={tab.name}
                >
                  {tab.name}
                </Tab>
              ))}
            </TabList>
            <TabIndicator
              mt="-2px"
              height="2px"
              bg="#5024DC"
              borderRadius="1px"
            />
          </div>
          <TabPanels>
            {tabs.map((tab, index) => (
              <TabPanel p={0} key={tab.name}>
                {<tab.component tabIndex={index} />}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </section>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen && isAllowToAccess}
        motionPreset="scale"
        size="6xl"
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />

        <ModalContent className="rounded-[20px]">
          <ModalHeader>
            <div>
              <p className="mb-8px text-[20px] font-medium text-[#0F0D1C]">
                Build your lists with AI Signals
              </p>
              <p className="text-[12px] font-[400] text-[#4A5264]">
                Start building a customized list using AI Signals
              </p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex gap-2">
            <div className="flex w-[50%] flex-col gap-3">
              {monitoringDetails.slice(0, 2).map((item) => (
                <div
                  key={item.title}
                  className={`flex cursor-pointer items-start gap-2 rounded border border-[#5024DC] p-[24px] shadow-md hover:shadow-lg`}
                  onClick={() => handleModalClick(item.title)}
                >
                  <div className="flex flex-col gap-2 text-sm text-darkTextGray">
                    <item.icon />
                    <Text className="text-[16px] font-[600] text-[#373F51]">
                      {item.title}
                    </Text>
                    <div className="text-[#4A5264]">{item.content}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mb-5 flex w-[50%] flex-col gap-3">
              {monitoringDetails.slice(2).map((item) => (
                <div
                  key={item.title}
                  className={`flex cursor-pointer items-start gap-3 rounded border border-[#5024DC] p-[24px] shadow-md hover:shadow-lg`}
                  onClick={() => handleModalClick(item.title)}
                >
                  <div className="flex flex-col gap-2 text-sm text-darkTextGray">
                    <item.icon />
                    <Text className="text-[16px] font-[600] text-[#373F51]">
                      {item.title}
                    </Text>
                    <div className="text-[#4A5264]">{item.content}</div>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );

  function handleModalClick(title: string) {
    const tabClicked = tabs.findIndex((tab) => tab.name === title);

    if (tabClicked !== -1) {
      setActiveTab(tabClicked);
    }
    onClose();
  }
  function isModalViewedInThisSession(title: string) {
    return sessionStorage.getItem(title) === "true";
  }
  function setModalViewed(title: string) {
    sessionStorage.setItem(title, "true");
  }
};
function AISignalPageHeader() {
  const toast = useToast();
  const currentUser = userStore((state) => state.currentUser);
  const updateJobChangeTracker = userStore(
    (state) => state.setJobChangeTracker,
  );
  const [selectedChannel, setSelectedChannel] = useState<Option>(
    () =>
      currentUser.jobChangeTracker?.selectedSlackChannel || {
        label: "",
        value: "",
      },
  );
  const [isEnabledAutoRefresh, setIsEnabledAutoRefresh] = useState(
    () => !!currentUser.jobChangeTracker?.isAutoRefreshEnabled,
  );
  const [slackChannelList, setSlackChannelList] = useState(
    () => currentUser.jobChangeTracker?.slackChannels || [],
  );
  const active = useTabState((state) => state.active);
  const isLoading = useTabState((state) => state.getCurrentLoading());
  const onImport = useTabState(
    (state) => state.getCurrentHandlers().onImport,
  ) as (val: boolean) => void;
  const importButtonTitle =
    useTabState((state) => state.getCurrentMetadata()?.importButtonTitle) ||
    "Import Data";
  const { data: providerList, refetch, isPending } = useProviderList();
  const slackProvider = providerList?.data?.find(
    (provider) => provider.name === "slack",
  );

  const updateJobChangeTrackerAndUserService = async (
    email: string,
    slackChannels: Option[],
    selectedChannel: Option,
    isAutoRefreshEnabled: boolean,
  ) => {
    const info = {
      slackChannels,
      selectedSlackChannel: selectedChannel,
      isAutoRefreshEnabled,
    };
    updateJobChangeTracker(info);
    await userService.updateJobChangeTrackerInfo({ email, info });
  };

  const getSlackChannels = useCallback(async () => {
    const response = await providerService.getSlackChannelList();
    const channels = response.data?.map((channel: SlackChannelType) => ({
      label: channel.name,
      value: channel.id,
    }));
    setSlackChannelList(channels);
    await updateJobChangeTrackerAndUserService(
      currentUser.Email,
      channels,
      selectedChannel,
      isEnabledAutoRefresh,
    );
  }, []);

  const handleSlackChannelSelection = async (selectedValue: Option) => {
    const selected = selectedValue ?? { label: "", value: "" };
    const isEnabled = selectedValue ? isEnabledAutoRefresh : false;
    setSelectedChannel(selected);
    setIsEnabledAutoRefresh(isEnabled);

    await updateJobChangeTrackerAndUserService(
      currentUser.Email,
      slackChannelList,
      selected,
      isEnabled,
    );
  };

  const handleAutoRefresh = async (isEnabled: boolean) => {
    if (selectedChannel?.value) {
      setIsEnabledAutoRefresh(isEnabled);
      await updateJobChangeTrackerAndUserService(
        currentUser.Email,
        slackChannelList,
        selectedChannel,
        isEnabled,
      );
    } else {
      toast({
        title: "Error",
        position: "top-right",
        description: "Please select the slack channel",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const clearState = async () => {
    await updateJobChangeTrackerAndUserService(
      currentUser.Email,
      [],
      { label: "", value: "" },
      false,
    );
  };

  useEffect(() => {
    setSelectedChannel(
      currentUser.jobChangeTracker?.selectedSlackChannel || {
        label: "",
        value: "",
      },
    );
    setSlackChannelList(currentUser.jobChangeTracker?.slackChannels || []);
    setIsEnabledAutoRefresh(
      currentUser.jobChangeTracker?.isAutoRefreshEnabled || false,
    );
  }, [currentUser.jobChangeTracker]);

  useEffect(() => {
    if (slackProvider) getSlackChannels();
  }, [getSlackChannels, slackProvider]);

  useEffect(() => {
    if (!slackProvider) {
      clearState();
    }
  }, [slackProvider]);

  return (
    <header className="flex w-full items-center justify-between px-[32px] py-[8px]">
      <Text fontSize={24} fontWeight={400}>
        AI Signals
      </Text>
      <div className="flex items-center gap-3">
        {active === 0 && (
          <div className="flex items-center gap-2">
            <Tooltip
              hasArrow
              label="Automatically refresh the job change once a month and send Slack notifications if any changes are detected."
            >
              <div className="flex !h-[35px] cursor-pointer items-center rounded-md border !border-[#8080801c] !bg-gray px-2 !text-sm hover:!shadow-md">
                <label
                  className="mr-2 cursor-pointer"
                  htmlFor="auto-run-switch"
                >
                  Auto Refresh
                </label>
                <Switch
                  isChecked={isEnabledAutoRefresh}
                  size="lg"
                  onChange={(e) => {
                    handleAutoRefresh(e.target.checked);
                  }}
                  id="auto-run-switch"
                />
              </div>
            </Tooltip>
            {slackProvider ? (
              <MultiSelectDropdown
                options={slackChannelList || []}
                onChange={(selectedValue) => {
                  handleSlackChannelSelection(selectedValue as Option);
                }}
                isMulti={false}
                isCreateAble={false}
                placeholder="Select slack channel"
                value={selectedChannel}
                closeMenuOnSelect
              />
            ) : (
              <div className="flex items-center gap-2">
                <Button
                  variant={"outline"}
                  size={"sm"}
                  leftIcon={
                    <img src={SlackLogo} alt="" width={12} height={12} />
                  }
                  onClick={() => {
                    providerService.openSlackOAuth();
                  }}
                >
                  Connect Slack
                </Button>
                <Button
                  size={"sm"}
                  variant={"outline"}
                  onClick={() => refetch()}
                  isLoading={isPending}
                >
                  <Icons.RefreshIcon />
                </Button>
              </div>
            )}
            <div className="flex items-center gap-2">
              <SyncHubspot />
            </div>
            <div className="hidden items-center gap-2">
              <Menu>
                <MenuButton as={Button} rightIcon={<Icons.ChevronDownIcon />}>
                  Select Frequency
                </MenuButton>
                <MenuList>
                  <MenuItem>Daily</MenuItem>
                  <MenuItem>Weekly</MenuItem>
                  <MenuItem>Monthly</MenuItem>
                  <MenuItem>Custom</MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        )}
        <Button
          isLoading={isLoading}
          leftIcon={<img src={ImportDataIcon} alt="import-data" />}
          isDisabled={!onImport}
          variant="solid"
          h={"38px"}
          fontSize={14}
          className="!bg-primary-gradient hover:opacity-80"
          colorScheme="primary"
          borderRadius={"6px"}
          onClick={() => {
            onImport?.(true);
          }}
        >
          {importButtonTitle}
        </Button>
      </div>
    </header>
  );
}

export default AccountMonitoring;

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("Enrich", useTabState);
}
