import {
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";

import Icons from "@/components/Icons";
import enrichment from "@/services/enrichment.service";
import { useEnrichStore } from "@/stores/enrich.store";
import { usePresetsStore } from "@/stores/presets.store";
import { useTableStore } from "@/stores/table.store";

import { useProviderList } from "@/hooks/useProviderData";
import { generateFilterPayload } from "@/lib/utils";
import { useModalStore } from "@/stores/modal.store";
import { userStore } from "@/stores/user.store";
import { EnrichmentApiCallPayload } from "@/types/enrichment.types";
import { Column } from "@/types/table.types";
import { convertSlateToText } from "@/utils";

interface Props {
  isDisabled?: boolean;
  showDivider?: boolean;
  apiPayload: Partial<EnrichmentApiCallPayload>;
  onClear?: () => void;
}
const SaveAndRunEnrichment: React.FC<Props> = ({
  isDisabled,
  apiPayload,
  showDivider = true,
  onClear,
}) => {
  const toast = useToast();
  const [rangeStart, setRangeStart] = useState<number>(1);
  const [rangeEnd, setRangeEnd] = useState(2);
  const queryClient = useQueryClient();

  const updateState = useModalStore((state) => state.updateState);
  const { mutateAsync, isPending } = useMutation({
    mutationFn: (payload: EnrichmentApiCallPayload) =>
      enrichment.callEnrichmentApi(payload),
  });
  const { data: providerList } = useProviderList();

  const apiKeySelect = useEnrichStore((state) => state.keySelect);
  const selectedEnrichments = useEnrichStore(
    (state) => state.selectedEnrichments,
  );
  const runConditionFormula = useEnrichStore(
    (state) => state.runConditionFormula,
  );
  const isInvalidConditionFormula = useEnrichStore(
    (state) => state.isInvalidConditionFormula,
  );
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const viewMode = useEnrichStore((state) => state.viewMode);
  const ownKeyProviderList = useEnrichStore(
    (state) => state.ownKeyProviderList,
  );

  const selectedRowsId = useTableStore((state) => state.selectedRowsId);
  const isSelectedAll = useTableStore((state) => state.isSelectedAll);
  const tableData = useTableStore((state) => state.tableData);
  const tableId = useTableStore((state) => state.tableData._id);
  const queryParams = useTableStore((state) => state.queryParams);
  const tableFilters = useTableStore((state) => state.tableFilters);
  const totalRows = useTableStore((state) => state.totalRows);
  const updatedTable = useTableStore((state) => state.updateState);
  const updateRowsForQueued = useTableStore(
    (state) => state.updateRowsForQueued,
  );
  const clearTableState = useTableStore((state) => state.clearState);
  const clearEnrichState = useEnrichStore((state) => state.clearState);
  const clearPresetState = usePresetsStore((state) => state.clearState);
  const refreshCurrentUser = userStore((state) => state.refreshCurrentUser);

  const isUserApiKeyExists = useMemo(() => {
    if (!providerList?.data) return false;

    return !!providerList.data?.filter(
      (provier) => provier.name === apiPayload.apiKeyName,
    ).length;
  }, [apiPayload.apiKeyName, providerList]);

  const clearAllState = () => {
    clearTableState();
    clearEnrichState();
    clearPresetState();
    onClear?.();
  };

  const handleKeyCheck = () => {
    const providerListData = providerList?.data || [];

    if (ownKeyProviderList?.length) {
      const providerList = ownKeyProviderList
        .map((keyName) =>
          providerListData.find((provider) => provider.name === keyName),
        )
        .filter(Boolean);

      if (providerList.length !== ownKeyProviderList.length) {
        throw new Error(
          "Please add your API key for the email providers. Some keys are missing.",
        );
      }
    }
  };

  const onSave = async ({
    isForAll = false,
    isForSelected = false,
    rowIds = [],
    customRowsCount: _customRowsCount = 0,
    start,
    end,
  }: {
    isForAll?: boolean;
    isForSelected?: boolean;
    rowIds?: string[];
    customRowsCount?: number;
    start?: number;
    end?: number;
  }) => {
    try {
      if (apiPayload.isWaterfall) {
        handleKeyCheck();
      }

      const { filterPayload } = generateFilterPayload(
        tableFilters.filters || [],
      );

      const isUseOwnKey = apiKeySelect === "ownApiKey";

      if (isUseOwnKey && !isUserApiKeyExists && apiPayload.apiKeyName) {
        toast({
          title: apiPayload.apiKeyErrorMessage || "Please add your own API key",
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }

      const customRowsCount =
        _customRowsCount || (end ? end + 1 : 0) - (start || 0);

      const payLoad: EnrichmentApiCallPayload = {
        enrichmentName: selectedEnrichments?.enrichmentName || "",
        runCondition: isInvalidConditionFormula
          ? ""
          : convertSlateToText(runConditionFormula || []).text
            ? runConditionFormula
            : null,
        isUseOwnKey,
        isSelectedAll: !!customRowsCount || isForAll || isSelectedAll,
        selectedRowsId:
          customRowsCount == 0 ? (rowIds.length ? rowIds : selectedRowsId) : [],
        tableId,
        queryParams,
        tableFilters: {
          filterBody: filterPayload,
        },
        startRange: start ? start - 1 : 0,
        endRange: end || 0,
        totalDataToProcess:
          rowIds.length || selectedRowsId.length || customRowsCount > 0
            ? Math.min(totalRows, customRowsCount)
            : totalRows,
        customRowsCount,
        ownKeyProviderList,
        ...apiPayload,
      };

      if (viewMode === "edit") {
        payLoad.action = "edit";
        payLoad.reRunColumnId = selectedColumnToEdit?._id;
      }

      await mutateAsync(payLoad, {
        onSuccess: (response) => {
          if (response?.error?.message?.includes("Not enough credit")) {
            updateState({
              isOpenModal: true,
              modalData: { modalType: "pricing" },
            });
          }
          if (!response.success || response.data.success === false) {
            toast({
              title:
                response?.error?.message ||
                response?.data?.message ||
                "Something went wrong",
              status: "error",
              duration: 3000,
              isClosable: true,
              position: "top-right",
            });
            return;
          }
          clearAllState();
          toast({
            title: response.data?.message || "Enrichment started",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });

          queryClient.invalidateQueries({
            queryKey: ["table-running-processes", tableId],
          });
          queryClient.invalidateQueries({
            queryKey: ["user-credit-details"],
          });

          refreshCurrentUser();

          const newAddedColumn = response?.data?.newColumns || [];

          const newlyAddedColumns = newAddedColumn
            .map((column: Column) => {
              if (tableData.columns.find((col) => col._id === column._id)) {
                return null;
              } else {
                return column;
              }
            })
            ?.filter(Boolean);

          const newColumns = tableData.columns.concat(newlyAddedColumns || []);

          updatedTable({
            tableData: {
              ...tableData,
              columns: newColumns,
            },
            selectedRowsId: [],
            isCreatedNewColumn: newlyAddedColumns?.length ? true : false,
          });

          updateRowsForQueued({
            isForSelected,
            newColumnId: newAddedColumn?.[0]?._id,
            customRowsCount,
            start,
            end,
          });

          setTimeout(() => {
            if (response?.data?.newColumnIdToRedirect) {
              const url = `/table/${response?.data?.newColumnIdToRedirect}`;
              window.open(url, "_blank");
            }
          }, 500);

          setTimeout(() => {
            updatedTable({
              isCreatedNewColumn: false,
            });
          }, 2000);
        },
        onError: (err) => {
          toast({
            title: err.message || "Something went wrong",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        },
      });
    } catch (error: any) {
      toast({
        title: error.message || "Something went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const handleRunForXRows = (rows: number) => {
    onSave({
      customRowsCount: rows,
    });
  };

  const xNumbers = [5, 10, 20];

  return (
    <div
      className={`flex flex-wrap items-center gap-y-2.5 ${showDivider && "border-t border-[#e7e8ec] p-4"}`}
    >
      <div
        className={`flex w-full items-center justify-between gap-2 rounded-[5px] bg-[#edf2f7] text-[14px] font-[600] text-[#1a202c] ${isDisabled || isPending || isInvalidConditionFormula || !(rangeStart && rangeEnd) ? "opacity-40" : "opacity-100"}`}
      >
        <div className="w-full py-1 text-center">
          <span>Save and run</span>
          <Input
            className="mx-4 !bg-white"
            size={"sm"}
            width={20}
            height={7}
            value={rangeStart || ""}
            type="number"
            onChange={(e) => setRangeStart(Number(e.target.value))}
            placeholder="1"
          />
          <span>to</span>

          <Input
            className="mx-4 !bg-white"
            size={"sm"}
            width={20}
            height={7}
            value={rangeEnd || ""}
            type="number"
            onChange={(e) => setRangeEnd(Number(e.target.value))}
          />
          <span>rows</span>
        </div>
        <Button
          size="sm"
          sx={{
            borderLeftRadius: 0,
            backgroundColor: "#CBD5E0 !important",
          }}
          isDisabled={
            isDisabled ||
            isPending ||
            isInvalidConditionFormula ||
            !rangeStart ||
            !rangeEnd
          }
          isLoading={isPending}
          onClick={() =>
            onSave({
              start: rangeStart,
              end: rangeEnd,
            })
          }
        >
          Run
        </Button>
      </div>
      <div className="flex w-full justify-between gap-x-4">
        {!!selectedRowsId.length && (
          <Button
            isDisabled={isDisabled || isPending || isInvalidConditionFormula}
            isLoading={isPending}
            size={"sm"}
            onClick={() => onSave({ isForSelected: true })}
            className="w-full flex-1"
          >
            Save and run for selected ( {selectedRowsId?.length} )
          </Button>
        )}
        <div className="flex w-full flex-1">
          <Button
            isDisabled={isDisabled || isPending || isInvalidConditionFormula}
            isLoading={isPending}
            size={"sm"}
            onClick={() => handleRunForXRows(10)}
            sx={{
              borderRightRadius: 0,
            }}
            className="flex-1"
          >
            Save and run for first 10
          </Button>
          <Menu placement="top-end">
            <MenuButton
              isDisabled={isDisabled}
              as={Button}
              size="sm"
              sx={{
                borderLeftRadius: 0,
                backgroundColor: "#CBD5E0 !important",
              }}
            >
              <Icons.ArrowDownIcon size={15} />
            </MenuButton>
            <MenuList>
              {xNumbers.map((number) => (
                <MenuItem
                  key={number}
                  onClick={() => handleRunForXRows(number)}
                  className="!text-[14px] !font-medium"
                >
                  {`Save and run for first ${number}`}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </div>
      </div>

      <Button
        isDisabled={isDisabled || isPending || isInvalidConditionFormula}
        isLoading={isPending}
        size={"sm"}
        onClick={() => onSave({ isForAll: true })}
        className="flex-1"
      >
        Save and run for all
      </Button>
    </div>
  );
};

export default React.memo(SaveAndRunEnrichment);
