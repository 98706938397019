import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";

import CustomButton from "@/components/Common/CustomButton";
import Icons from "@/components/Icons";
import TableList from "../TableList";
import AIAssistant from "@/components/AIAssistant";

import tableService from "@/services/table.service";
import teamService from "@/services/team.service";
import { useModalStore } from "@/stores/modal.store";
import { useTableStore } from "@/stores/table.store";
import { Team } from "@/types/team.types";
import { setCookie } from "@/utils";
import userService from "@/services/user.service";
import { userStore } from "@/stores/user.store";
import { useTableState } from "../TableList/hooks/useTableState.store";

const StarterSection = () => {
  const [workspaces, setWorkspaces] = useState<Team[]>([]);

  const searchValue = useTableState((state) => state.search);
  const setSearchValue = useTableState((state) => state.updateState);

  const selectedWorkSpace = useTableStore((state) => state.selectedWorkSpace);
  const updateTableState = useTableStore((state) => state.updateState);
  const updateUserStore = userStore((state) => state.updateState);
  const cookies = new Cookies();

  const { mutateAsync } = useMutation({
    mutationFn: () => teamService.getWorkspaces(),
    onSuccess: (response) => {
      const workspaceId = cookies.get("workspaceId");

      if (workspaceId && Array.isArray(response.data?.sharedWorkspaces)) {
        const selectedWorkspace = response.data.sharedWorkspaces.find(
          (workspace: Team) => workspace._id === workspaceId,
        );
        updateTableState({
          selectedWorkSpace: selectedWorkspace,
        });
      }

      setWorkspaces(response?.data?.sharedWorkspaces || []);
    },
  });

  const { mutateAsync: updateCredits } = useMutation({
    mutationFn: () => userService.getUserCreditDetails(),
    onSuccess: (response: any) => {
      updateUserStore({
        creditDetails: {
          creditLeft: response?.data?.creditLeft,
          creditUsed: response?.data?.creditUsed,
          planType: response?.data?.planType,
        },
      });
    },
  });

  const { mutateAsync: getWorkspaceTableLists } = useMutation({
    mutationFn: () => tableService.getTableList(),
  });

  const handleWorkspaceChange = async (workspace: Team | null) => {
    setCookie("workspaceId", workspace?._id || "");
    updateTableState({
      selectedWorkSpace: workspace,
    });
    await getWorkspaceTableLists();

    await updateCredits();

    window.location.reload();
  };
  useEffect(() => {
    mutateAsync();
  }, []);

  const navigate = useNavigate();
  const { updateState } = useModalStore((state) => state);

  return (
    <div className="h-fit w-full bg-white pb-[32px]">
      <div className="mx-[24px] flex items-center justify-between">
        <div className="flex items-center justify-start gap-[16px] py-[16px]">
          <CustomButton
            buttonText={"Get Started"}
            variant={"primary-gradient"}
            onClick={() =>
              updateState({
                isOpenModal: true,
                modalData: { modalType: "get-started" },
              })
            }
          />
          <Button
            justifySelf="flex-end"
            variant={"outline"}
            className="text-[16px] !font-medium"
            onClick={() => {
              navigate({ to: "/playbooks" });
            }}
          >
            Use Templates
          </Button>
        </div>
        {workspaces?.length > 0 && (
          <Menu autoSelect={false}>
            <MenuButton
              as={Button}
              variant={"outline"}
              rightIcon={<Icons.ChevronDownIcon />}
              style={{
                fontSize: "15px",
                borderRadius: "6px",
              }}
            >
              Switch Team Workspace
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  handleWorkspaceChange(null);
                }}
                className={!selectedWorkSpace ? "!bg-bgSecondary" : ""}
              >
                Personal workspace
              </MenuItem>
              {workspaces?.map((workspace, index) => (
                <MenuItem
                  key={`workspaces-${index}`}
                  onClick={() => {
                    handleWorkspaceChange(workspace);
                  }}
                  className={
                    workspace._id === selectedWorkSpace?._id
                      ? "!bg-bgSecondary"
                      : ""
                  }
                >
                  <span className="capitalize">{workspace.name}</span>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </div>

      <AIAssistant />

      {/* Table section */}
      <div className="mx-[24px] h-fit rounded-[12px] border border-[#D0D4DC] p-[24px]">
        <div className="overflow-hidden py-2">
          <div className="mb-4 flex items-center justify-between">
            <InputGroup width="100%" className="mr-1">
              <InputLeftElement>
                <Icons.SearchIcon size={20} color="gray" />
              </InputLeftElement>
              <Input
                style={{ borderRadius: "20px" }}
                placeholder="Search tables..."
                size="md"
                variant="outline"
                value={searchValue}
                onChange={(event) => {
                  setSearchValue({ search: event?.target.value ?? "" });
                }}
              />
            </InputGroup>
          </div>
          <TableList />
        </div>
      </div>
    </div>
  );
};

export default StarterSection;
