import { Controls, Edge, Node, ReactFlow } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useEffect, useRef, useState } from "react";

import { useTableList } from "@/hooks/useTableList";
import { TableData } from "@/types/table.types";
import { createSearchLeadsStore, TabContext } from "../PeopleSearch/store";
import FilePeopleSourceNode from "./FindPeopleSource/FindPeopleSourceNode";
import HubspotStorage from "./HubspotStorage/HubspotStorage";
import PersanaTable from "./PersanaTable/PersanaTable";
import SlackNotifications from "./SlackNotifications/SlackNotifications";
import { Button } from "@chakra-ui/react";
import Icons from "../Icons";
import { useNavigate } from "@tanstack/react-router";

export interface CustomNodeProps {
  data: {
    showToolbar?: boolean;
    isDisabled?: boolean;
    isConfigured?: boolean;
    tableData?: TableData;
    refetch?: any;
  };
}

const AUTO_PILOT_NODE_ID = {
  FIND_PEOPLE_SOURCE: "findPeopleSource",
  PERSANA_TABLE: "persanaTable",
  HUBSPOT_STORAGE: "hubspotStorage",
  SLACK_NOTIFICATIONS: "slackNotifications",
};

const INITIAL_TOOLBAR_STATE = {
  [AUTO_PILOT_NODE_ID.FIND_PEOPLE_SOURCE]: false,
  [AUTO_PILOT_NODE_ID.PERSANA_TABLE]: false,
  [AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE]: false,
  [AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS]: false,
};

const AutoPilotNode: React.FC = () => {
  const navigate = useNavigate();
  const { data: allTables, refetch } = useTableList();
  const autoPilotTable = allTables?.find((item) => item.isAutoPilot);
  const isConfigured = !!autoPilotTable;

  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges] = useState<Edge[]>([
    {
      id: "e1-2",
      source: AUTO_PILOT_NODE_ID.FIND_PEOPLE_SOURCE,
      target: AUTO_PILOT_NODE_ID.PERSANA_TABLE,
    },
  ]);
  const [toolbarState, setToolbarState] = useState(INITIAL_TOOLBAR_STATE);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleNodeClick = (e: React.MouseEvent, node: Node) => {
    e.stopPropagation();
    setToolbarState(() => ({
      ...INITIAL_TOOLBAR_STATE,
      [node.id]: true,
    }));
  };

  const handleCanvasClick = () => {
    setToolbarState(INITIAL_TOOLBAR_STATE);
  };

  useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const nodeWidth = 100;
      const topMargin = 50;

      const startX = (containerWidth - nodeWidth) / 2.5;

      const updatedNodes: Node[] = [
        {
          id: AUTO_PILOT_NODE_ID.FIND_PEOPLE_SOURCE,
          type: "customA",
          position: { x: startX, y: topMargin },
          data: {
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.FIND_PEOPLE_SOURCE],
            isConfigured,
            tableData: autoPilotTable,
            refetch,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.PERSANA_TABLE,
          type: "customB",
          position: { x: startX, y: topMargin + 150 },
          data: {
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.PERSANA_TABLE],
            isDisabled: !isConfigured,
            tableData: autoPilotTable,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE,
          type: "customC",
          position: { x: startX, y: topMargin + 300 },
          data: {
            isConfigured:
              autoPilotTable?.metaData?.storeOnHubspot !== undefined &&
              autoPilotTable?.metaData?.storeOnHubspot !== null,
            isDisabled: !isConfigured,
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.HUBSPOT_STORAGE],
            tableData: autoPilotTable,
            refetch,
          },
        },
        {
          id: AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS,
          type: "customD",
          position: { x: startX, y: topMargin + 450 },
          data: {
            isConfigured:
              autoPilotTable?.metaData?.sendSlackNotifications != undefined &&
              autoPilotTable?.metaData?.sendSlackNotifications != null,
            isDisabled: !isConfigured,
            showToolbar: toolbarState[AUTO_PILOT_NODE_ID.SLACK_NOTIFICATIONS],
            tableData: autoPilotTable,
            refetch,
          },
        },
      ];

      setNodes(updatedNodes);
    }
  }, [toolbarState, allTables, isConfigured, autoPilotTable]);

  return (
    <>
      <div className="py-[8px] px-[32px] flex items-center justify-between bg-white">
        <p className="text-[24px] font-bold leading-[32px]">Autopilot</p>
        <Button
          colorScheme="primary"
          variant="outline"
          leftIcon={<Icons.BackIcon />}
          className="!bg-light !text-textPurple !border-none h-[40px] rounded-md"
          onClick={() => {
            navigate({ to: "/auto-pilot" });
          }}
        >
          Go Back
        </Button>
      </div>
      <div
        ref={containerRef}
        style={{ width: "calc(100vw - 80px)", height: "92vh" }}
        onClick={handleCanvasClick}
      >
        <TabContext.Provider value={createSearchLeadsStore("autopilot_1")}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={{
              customA: FilePeopleSourceNode,
              customB: PersanaTable,
              customC: HubspotStorage,
              customD: SlackNotifications,
            }}
            onNodeClick={handleNodeClick}
          >
            <Controls />
          </ReactFlow>
        </TabContext.Provider>
      </div>
    </>
  );
};

export default AutoPilotNode;
