import React from "react";
import { NodeToolbar, Position } from "@xyflow/react";
import { useDisclosure } from "@chakra-ui/react";

import Toolbar from "../Toolbar";
import Configurations from "./Configurations";
import CustomNodeCard from "../CustomNodeCard";

import { CustomNodeProps } from "..";
import StorageImg from "@/assets/svgs/auto-pilot-storage.svg";

const HubspotStorage: React.FC<CustomNodeProps> = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleConfig = () => {
    onOpen();
  };

  const handleClose = (isRefetch: boolean) => {
    isRefetch && data?.refetch();
    onClose();
  };

  return (
    <>
      {!data.isDisabled && data.showToolbar && (
        <NodeToolbar isVisible={true} position={Position.Right}>
          <Toolbar
            isConfigured={!!data.isConfigured}
            handleConfig={handleConfig}
          />
        </NodeToolbar>
      )}
      <CustomNodeCard
        title="Send to Hubspot"
        subtitle="Storage"
        configStatus="Hubspot configured"
        isConfigured={!!data.isConfigured}
        isDisabled={!!data.isDisabled}
        rightIcon={
          "https://persana-assets.s3.us-west-1.amazonaws.com/hubspot-logo.png"
        }
        imgSrc={StorageImg}
      />
      <Configurations
        isConfigured={!!data.isConfigured}
        tableData={data?.tableData}
        isOpen={isOpen}
        onClose={handleClose}
      />
    </>
  );
};

export default HubspotStorage;
