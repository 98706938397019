import { Box } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { useGridOptions } from "./hooks/useGridOptions";
import { LoadingView } from "./components/LoadingView";
import { NoTablesView } from "./components/NoTablesView";
import { CreateNew } from "./components/CreateNew";
import StyleProvider from "@/providers/StyleProvider";
import "./index.css";
import { TRowId } from "./types";

const TableList = ({
  variant = "full",
}: {
  path?: TRowId[];
  variant?: "mini" | "full";
}) => {
  const gridOptions = useGridOptions();
  const initOptions =
    variant === "mini"
      ? {
          editable: false,
          showUpdatedAt: false,
          showFolders: false,
        }
      : {
          editable: true,
          showUpdatedAt: true,
          showFolders: true,
        };
  return (
    <StyleProvider>
      <Box overflowX="auto" className="folder-table mt-4">
        <Box overflowY="auto" maxHeight="540px">
          {initOptions.editable && <CreateNew mr="2em" />}
          <AgGridReact
            context={{
              ...(gridOptions.context ?? null),
              initOptions,
              variant,
            }}
            className="custom-scrollbar ag-theme-quartz !h-[540px]"
            gridOptions={gridOptions}
            noRowsOverlayComponent={NoTablesView}
            loadingOverlayComponent={LoadingView}
          />
        </Box>
      </Box>
    </StyleProvider>
  );
};
export default TableList;
