import { useUserConfig } from "@/hooks/useUserConfig";
import { InputtedData } from "../store";
import objectHash from "object-hash";
import { pick } from "lodash";

export interface Filter {
  naturalFilters?: {
    natural_query: string;
    query_title: string;
  };
  name?: string;
  sideBarInput: InputtedData;
  currentSearchPayload: any;
  valueLabels: Record<string, string>;
  assistantInput: string;
  savedDate?: string;
}

interface UseFilterManager {
  save: (newFilter: Filter) => void;
  list: () => Filter[];
  remove: (index: number) => void;
  update: (filter: Filter) => void;
  isSaved: (filter: Filter) => boolean;
  isSaving: boolean;
  isDeleting: boolean;
}

export const useFilterManager = (): UseFilterManager => {
  const [userConfigQuery, userConfigMutation] = useUserConfig(
    "people_search_filters",
  );

  const listFilters = (): Filter[] => {
    return (userConfigQuery.data?.config?.people_search_filters ||
      []) as Filter[];
  };

  const saveFilter = (newFilter: Filter): void => {
    const existingFilters = listFilters();
    const baseTitle =
      newFilter.naturalFilters?.query_title || "Untitled Filter";
    newFilter.name = newFilter.name || baseTitle;

    const sameTitleFilters = existingFilters
      .map((filter) => {
        if (filter.name) {
          if (filter.name?.startsWith(newFilter.name!)) return filter.name;
        } else if (
          filter.naturalFilters?.query_title?.startsWith(newFilter.name!)
        ) {
          return filter.naturalFilters?.query_title;
        }
      })
      .filter(Boolean);

    if (sameTitleFilters.length > 0) {
      const titleNumbers = sameTitleFilters
        .map((title) => {
          const match = title?.match(/\((\d+)\)$/);
          return match ? parseInt(match[1], 10) : 1;
        })
        .filter(Boolean);

      const maxNumber = Math.max(...titleNumbers, 1);
      newFilter.name = `${newFilter.name} (${maxNumber + 1})`;
    }

    const filterWithDate = {
      ...newFilter,
      savedDate: new Date().toISOString(),
    };

    userConfigMutation.mutate([filterWithDate, ...existingFilters]);
  };

  const updateFilter = (filter: Filter) => {
    const existingFilters = listFilters();
    const oldfilterIndex = existingFilters.findIndex(
      (f) => f.savedDate === filter.savedDate,
    );
    if (oldfilterIndex === -1) return console.warn("Filter not found");
    userConfigMutation.mutate(existingFilters.with(oldfilterIndex, filter));
  };

  const removeFilter = (index: number): void => {
    const existingFilters = listFilters();
    const updatedFilters = existingFilters.filter((_, i) => i !== index);
    userConfigMutation.mutate(updatedFilters);
  };

  const isSaved = (filter: Filter): boolean => {
    const existingFilters = listFilters();
    /**
     * naturalFilters?: {
    natural_query: string;
    query_title: string;
  };
  name?: string;
  sideBarInput: InputtedData;
  currentSearchPayload: any;
  assistantInput: string;
     */
    const filterHash = objectHash(
      pick(filter, [
        "naturalFilters",
        "sideBarInput",
        "currentSearchPayload",
        "assistantInput",
      ]),
    );
    return existingFilters.some(
      (savedFilter) =>
        objectHash(
          pick(savedFilter, [
            "naturalFilters",
            "sideBarInput",
            "currentSearchPayload",
            "assistantInput",
          ]),
        ) === filterHash,
    );
  };

  return {
    save: saveFilter,
    list: listFilters,
    remove: removeFilter,
    update: updateFilter,
    isSaved,
    isSaving: userConfigMutation.isPending,
    isDeleting: userConfigMutation.isPending,
  };
};
