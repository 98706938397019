import { create } from "zustand";
import { GridApi } from "ag-grid-community";
import { useMemo } from "react";
import { SelectionState, SelectionRange, Cell, Overlay } from "./types";
import { userStore } from "@/stores/user.store";

const defaultOverlay = { left: 0, top: 0, width: 0, height: 0 };

export const useSelectionStore = create<SelectionState>((set) => {
  userStore.subscribe((state) => {
    const currentUser = state.currentUser;
    const isUserPaid =
      currentUser?.planType?.toLowerCase() !== "free" ||
      currentUser.role === "admin";
    set({
      features: {
        copy: isUserPaid,
        paste: isUserPaid,
        select: isUserPaid,
        delete: true,
      },
    });
  });
  return {
    features: {
      copy: false,
      paste: false,
      select: false,
      delete: true,
    },
    gridApi: null,
    range: null,
    isSelecting: false,
    setGridApi: (gridApi) => set({ gridApi }),
    setRange: (range) => {
      set((state) => {
        const overlay = deriveOverlay(range);
        return { ...state, range, overlay };
      });
    },
    setIsSelecting: (isSelecting) => set({ isSelecting }),
    clearSelection: () => set({ range: null, isSelecting: false }),
  };
});

function deriveOverlay(range: SelectionRange | null): Overlay | null {
  if (!range) return defaultOverlay;

  const { startCell, endCell } = range;
  const [startCellPosition, endCellPosition] = [
    getCellPosition(startCell),
    getCellPosition(endCell),
  ];
  if (!startCellPosition || !endCellPosition) return defaultOverlay;
  const left = Math.min(startCellPosition.left, endCellPosition.left);
  const top = Math.min(startCellPosition.top, endCellPosition.top);
  const right = Math.max(startCellPosition.right, endCellPosition.right);
  const bottom = Math.max(startCellPosition.bottom, endCellPosition.bottom);
  const width = right - left;
  const height = bottom - top;
  return { top, left, width, height };
}

function getCellPosition(cell: Cell) {
  const baseElementRect =
    document
      .querySelector(".ag-center-cols-container")
      ?.getBoundingClientRect() || null;
  const cellElementRect =
    document
      .querySelector(
        `.ag-row[row-index="${cell.rowIndex}"] > .ag-cell[col-id="${cell.colId}"]`,
      )
      ?.getBoundingClientRect() || null;
  if (!baseElementRect || !cellElementRect) return null;
  const relativePosition = {
    left: cellElementRect.x - baseElementRect.x,
    top: cellElementRect.y - baseElementRect.y,
    right: cellElementRect.x - baseElementRect.x + cellElementRect.width,
    bottom: cellElementRect.y - baseElementRect.y + cellElementRect.height,
  };
  return relativePosition;
}

export const useOverlayPosition = () => {
  const range = useSelectionStore((state) => state.range);
  return useMemo(() => deriveOverlay(range), [range]);
};

export const getFirstCell = (
  range: SelectionRange | null,
  gridApi: GridApi,
): Cell | null => {
  if (!range) return null;
  const firstColId = [range.startCell.colId, range.endCell.colId].sort(
    (a, b) => {
      const aIndex =
        gridApi.getColumns()?.findIndex((col) => col.getColId() === a) ?? -1;
      const bIndex =
        gridApi.getColumns()?.findIndex((col) => col.getColId() === b) ?? -1;
      return aIndex - bIndex;
    },
  )[0];
  const firstRowIndex = Math.min(
    range.startCell.rowIndex,
    range.endCell.rowIndex,
  );
  return {
    colId: firstColId,
    rowIndex: firstRowIndex,
  };
};
