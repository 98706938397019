import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import SelectableTextEditor from "../../Common/SelectableTextEditor";

import KeySelect from "@/components/Common/KeySelect";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { useEnrichStore } from "@/stores/enrich.store";
import { usePresetsStore } from "@/stores/presets.store";
import { Divider } from "@chakra-ui/react";
import ConditionalFormula from "../../Common/ConditionalFormula";
import { maxCostOptions, supportedGPTModels } from "./constants";
import OtherOptions from "./OtherOptions";
import Outputs from "./Outputs";
import SelectTemplate from "./SelectTemplate";
import { CustomOutput } from "./types";

const PersanaAgent3 = () => {
  const [selectedOptions, setSelectedOptions] = useState<{
    model: Option;
    maxCost: Option;
  }>({
    model: supportedGPTModels.find((item) => item.value === "gpt-4o-mini") || {
      value: "",
      label: "",
    },
    maxCost: { value: "", label: "" },
  });
  const [customOutputs, setCustomOutputs] = useState<CustomOutput[]>([]);

  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = usePresetsStore((state) => state.updateState);
  const { selectedPresetValues, condition, model, maxCost } = usePresetsStore(
    (state) => ({
      selectedPresetValues: state.selectedPresetValues,
      condition: state.condition,
      model: state.model,
      maxCost: state.maxCost,
    }),
  );
  const updateEnrichmentState = useEnrichStore((state) => state.updateState);

  useEffect(() => {
    if (condition) {
      updateEnrichmentState({
        runConditionFormula: condition,
        isInvalidConditionFormula: false,
      });
    }
    if (model) {
      setSelectedOptions((prev) => ({
        ...prev,
        model: supportedGPTModels.find((m) => m.value === model) || {
          value: "",
          label: "",
        },
      }));
      if (maxCost) {
        setSelectedOptions((prev) => ({
          ...prev,
          maxCost: maxCostOptions.find((m) => m.value === maxCost) || {
            value: "",
            label: "",
          },
        }));
      }
    }
  }, [condition, model, maxCost]);

  const payload = {
    prompts: selectedPresetValues,
    model: selectedOptions.model?.value,
    maxCost: selectedOptions.maxCost?.value,
    customOutputs: customOutputs?.filter(
      (output) => !!output.fieldName?.trim(),
    ),
    apiKeyName: "openAiApiKey",
  };

  const updateSelectedOptions = useCallback((key: string, value: Option[]) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const handleAddCustomOutput = useCallback(() => {
    setCustomOutputs((prev) => [
      ...prev,
      {
        fieldName: `Field ${prev.length + 1}`,
        description: "",
        id: uuidv4(),
      },
    ]);
  }, []);

  const handleDeleteCustomOutput = useCallback((id: string) => {
    setCustomOutputs((prev) => prev.filter((output) => output.id !== id));
  }, []);

  const onCustomOutputChange = useCallback(
    (id: string, key: string, value: string) => {
      setCustomOutputs((prev) =>
        prev.map((output) => {
          if (output.id === id) {
            return {
              ...output,
              [key]: value,
            };
          }
          return output;
        }),
      );
    },
    [],
  );

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as any;

      console.log("enrichmentData", enrichmentData);

      if (enrichmentData) {
        updateState({
          selectedPresetValues: enrichmentData.prompts || "",
        });
        updateEnrichmentState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
          keySelect: enrichmentData?.isUseOwnKey
            ? "ownApiKey"
            : "persanaApiKey",
        });

        if (enrichmentData.customOutputs) {
          setCustomOutputs(enrichmentData.customOutputs);
        }

        if (enrichmentData.model) {
          const model = supportedGPTModels.find(
            (model) => model.value === enrichmentData.model,
          );

          setSelectedOptions((prevState) => ({
            ...prevState,
            model: model || { value: "", label: "" },
          }));
        }

        if (enrichmentData.maxCost) {
          const maxCost = maxCostOptions.find(
            (cost) => cost.value === enrichmentData.maxCost,
          );
          setSelectedOptions((prevState) => ({
            ...prevState,
            maxCost: maxCost || { value: "", label: "" },
          }));
        }
      }
    }
  }, [selectedColumnToEdit]);

  console.log("selectedOptions", selectedOptions);

  return (
    <>
      <div className="grow overflow-y-auto p-4">
        <WatchTutorial
          subText={
            "Our most advanced agent capable of strong reasoning, browsing the internet and searching the web."
          }
          link=""
        />
        <Divider mb={4} />
        <SelectTemplate
          payload={{
            model: selectedOptions.model?.value,
            maxCost: selectedOptions.maxCost?.value,
          }}
        />
        <KeySelect providerName={"openAiApiKey"} providerTitle="OpenAI" />
        <SelectableTextEditor
          title="Write your query here"
          text={selectedPresetValues || ""}
          setText={(newInputText: any) => {
            updateState({
              selectedPresetValues: newInputText,
            });
          }}
          rows={8}
          callFrom="persana-agent-2"
          placeholder="Visit the website of the company at /Company Website, then explore their features section. Identify the most advanced feature they offer and how it differs from the basic features available."
        />

        <OtherOptions
          selectedOptions={selectedOptions}
          updateSelectedOption={updateSelectedOptions}
        />

        <Outputs
          customOutputs={customOutputs}
          handleAddCustomOutput={handleAddCustomOutput}
          handleDeleteCustomOutput={handleDeleteCustomOutput}
          onCustomOutputChange={onCustomOutputChange}
        />

        <ConditionalFormula />
      </div>
      <SaveAndRunEnrichment
        isDisabled={!selectedPresetValues}
        apiPayload={payload}
      />
    </>
  );
};

export default PersanaAgent3;
