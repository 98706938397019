import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import Sidebar from "@/components/PeopleSearch/Sidebar";

import leadService from "@/services/leads.service";
import tableService from "@/services/table.service";

import { userStore } from "@/stores/user.store";
import { TableData } from "@/types/table.types";
import { useSearchLeadsStore } from "@/components/PeopleSearch/store";
import { convertSearchPeoplePayload, filterValidObjKeys } from "@/utils";

type Props = {
  isConfigured: boolean;
  isOpen: boolean;
  tableData?: TableData;
  onClose: (isRefetch: boolean) => void;
};

const Configurations = ({
  isConfigured,
  isOpen,
  tableData,
  onClose,
}: Props) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const currentUser = userStore((state) => state.currentUser);
  const planType = currentUser.planType;
  const maxLeads = planType?.toLocaleLowerCase() === "free" ? 25 : 1000;
  const [isLoading, setIsLoading] = useState(false);
  const currentSearchPayload = useSearchLeadsStore(
    (state) => state.sideBarInput,
  );
  console.log(currentSearchPayload);
  const handleAutoPilot = async () => {
    setIsLoading(true);
    const convertedPayload = convertSearchPeoplePayload(currentSearchPayload);
    const validInputtedData = filterValidObjKeys(convertedPayload);
    const payload = {
      ...validInputtedData,
      isForFindEmail: false,
      selectedLeads: [],
      customSelectionNumber: 0,
      isSelectAll: true,
      isForAutoPilot: true,
      totalEntries: maxLeads,
    };
    const response = await leadService.runAutoPilot(
      payload,
      "create-auto-pilot-table",
    );
    queryClient.refetchQueries({
      queryKey: ["tables"],
    });
    window.open(`/table/${response?.data?.tableData?._id}`, "_blank");
    setIsLoading(false);
    onClose(true);
  };

  const updatePreferences = async () => {
    setIsLoading(true);
    const convertedPayload = convertSearchPeoplePayload(currentSearchPayload);
    const validInputtedData = filterValidObjKeys(convertedPayload);
    const response = await tableService.updateTableDataV2(
      tableData?._id || "",
      {
        metaData: {
          ...validInputtedData,
        },
      },
    );
    if (response.success) {
      toast({
        title: "Success",
        description: "Successfully updated the preferences.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } else {
      toast({
        title: "Error",
        description: "Something went wrong.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    onClose(true);
    setIsLoading(false);
  };

  return (
    <Modal
      size={"5xl"}
      isOpen={isOpen}
      onClose={() => onClose(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Auto Pilot - Filter Preferences</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="custom-scrollbar max-h-[500px] min-h-[500px] overflow-y-auto">
          <Sidebar isForAutoPilot />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            size={"sm"}
            mr={3}
            onClick={() => onClose(false)}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            variant="outline"
            size={"sm"}
            isLoading={isLoading}
            onClick={() =>
              isConfigured ? updatePreferences() : handleAutoPilot()
            }
          >
            {isConfigured ? "Update" : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Configurations;
