import { Avatar, Link } from "@chakra-ui/react";
import Icons from "../Icons";

export const UserDetailsRenderer = (props: any) => {
  return (
    <div className="flex gap-4">
      <div className="flex items-center justify-center">
        <div className="w-[35px]">
          <Avatar name={props?.data?.name} src={props?.data?.profilePhoto} />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          {props?.data?.name && (
            <span className="text-[14px] font-[500] text-[#677084]">
              {`${props?.data?.name}`}{" "}
            </span>
          )}
          {props?.data?.linkedInUrl && (
            <Link href={props?.data?.linkedInUrl} target="_blank">
              <Icons.LinkedInIcon className="text-lg text-[#0077B5]" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
