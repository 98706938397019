import { useEffect, useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import _ from "lodash";
import { useOverlayPosition, useSelectionStore } from "./useSelectionStore"; // Adjust the import path as needed
import { OverlayActions } from "./components/Actions";

const Overlay = () => {
  const range = useSelectionStore((state) => state.range);
  const overlay = useOverlayPosition();
  const gridApi = useSelectionStore((state) => state.gridApi);
  const containerRef = useRef<Element | null>(null);
  const isSingleCell = useMemo(
    () => _.isEqual(range?.startCell, range?.endCell),
    [range],
  );
  useEffect(() => {
    containerRef.current = document.querySelector(".ag-center-cols-container");

    if (containerRef.current && gridApi) {
      containerRef.current.classList.add("relative");
      return () => {
        containerRef.current?.classList.remove("relative");
      };
    }
  }, [gridApi]);

  if (!overlay || !gridApi || !containerRef.current) {
    return null;
  }

  return createPortal(
    <div
      id="selection-overlay"
      style={{
        transition: "all 0.1s ease",
        position: "absolute",
        left: `${overlay.left}px`,
        top: `${overlay.top}px`,
        width: `${overlay.width}px`,
        height: `${overlay.height}px`,
        backgroundColor: "rgba(0, 123, 255, 0.1)",
        border: "1px solid rgba(0, 123, 255, 1)",
        pointerEvents: "none",
        opacity: isSingleCell ? 0 : 1,
        zIndex: 1,
      }}
    >
      <OverlayActions />
    </div>,
    containerRef.current,
  );
};

export default Overlay;
