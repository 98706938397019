import Icons from "@/components/Icons";
import { Button, ButtonGroup, IconButton } from "@chakra-ui/react";
import { useRef } from "react";
import { useTableSearchStore } from "./tableSearch.store";

const TableSearch = () => {
  const {
    searchText,
    setSearchText,
    searchedCells,
    setSearchedCells,
    searchIndex,
    setSearchIndex,
    setIsSearching,
    rowsData,
    gridApi,
    isSearching,
    setMatchCount,
    matchCount,
  } = useTableSearchStore();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value);
    setIsSearching(false); // Reset search status on input change
  };

  const handleSearch = () => {
    if (searchText.trim() === "") {
      setIsSearching(false);
      setMatchCount(0);
      return;
    }

    const foundCells: { rowIndex: number; field: string }[] = [];

    rowsData.forEach((row, rowIndex) => {
      Object.keys(row.cells).forEach((cellId) => {
        const cellValue = row.cells[cellId].value;
        if (
          cellValue &&
          cellValue.toString().toLowerCase().includes(searchText.toLowerCase())
        ) {
          foundCells.push({ rowIndex, field: cellId });
        }
      });
    });

    setSearchedCells(foundCells);
    setSearchIndex(foundCells.length > 0 ? 0 : -1);
    setIsSearching(true);
    setMatchCount(foundCells.length);

    // Ensure the first matched cell is visible
    if (foundCells.length > 0) {
      const { rowIndex } = foundCells[0];
      gridApi?.ensureIndexVisible(rowIndex, "middle");
    }
  };

  const handleNextSearch = () => {
    if (searchedCells.length > 0) {
      const newIndex = (searchIndex + 1) % searchedCells.length;
      setSearchIndex(newIndex);
      const { rowIndex } = searchedCells[newIndex];
      gridApi?.ensureIndexVisible(rowIndex, "middle");
    }
  };

  const handlePrevSearch = () => {
    if (searchedCells.length > 0) {
      const newIndex =
        (searchIndex - 1 + searchedCells.length) % searchedCells.length;
      setSearchIndex(newIndex);
      const { rowIndex } = searchedCells[newIndex];
      gridApi?.ensureIndexVisible(rowIndex, "middle");
    }
  };

  const handleSearchKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      if (isSearching) {
        handleNextSearch();
      } else {
        handleSearch();
      }
    }
  };

  return (
    <div className="flex max-w-[12em] items-center justify-end">
      <div className="border-gray-300 flex h-full items-center rounded-md border pr-[2px]">
        <div className="flex items-center px-3">
          <Icons.SearchIcon />
        </div>
        <input
          type="text"
          autoComplete="on"
          className="text-gray-700 placeholder:text-gray-500 h-[2em] w-full rounded-md border-none bg-white p-0 focus:outline-none"
          placeholder="Search"
          value={searchText}
          onChange={handleSearchInputChange}
          onKeyDown={handleSearchKeyPress}
          ref={searchInputRef}
        />
        {!isSearching ? (
          !!searchText && (
            <Button
              colorScheme="purple"
              variant="ghost"
              onClick={handleSearch}
              className="!h-[25px] !text-[12px]"
            >
              Search
            </Button>
          )
        ) : (
          <span className="text-gray-500 bg-gray-100 rounded-md px-2 py-1 text-xs">
            {searchIndex + 1}/{matchCount}
          </span>
        )}
      </div>
      {isSearching && (
        <ButtonGroup
          size="xs"
          variant={"solid"}
          isAttached
          colorScheme="purple"
          className="ml-2 flex items-center justify-center"
        >
          {/* <Icons.ArrowRoundDownIcon
            size={26}
            className="cursor-pointer text-[#805ad6]"
            onClick={handleNextSearch}
          />
          <Icons.ArrowRoundUpIcon
            size={26}
            className="cursor-pointer text-[#805ad6]"
            onClick={handlePrevSearch}
          />
          <Icons.CloseIcon
            size={20}
            onClick={() => {
              setSearchText("");
              setIsSearching(false);
            }}
            className="cursor-pointer text-[#805ad6]"
          /> */}
          <IconButton
            aria-label="Next"
            onClick={handleNextSearch}
            icon={<Icons.ArrowRoundDownIcon size="20" />}
          />
          <IconButton
            aria-label="Previous"
            onClick={handlePrevSearch}
            icon={<Icons.ArrowRoundUpIcon size="20" />}
          />
          <IconButton
            aria-label="Close"
            onClick={() => {
              setSearchText("");
              setIsSearching(false);
            }}
            icon={<Icons.CloseIcon size="16" />}
          />
        </ButtonGroup>
      )}
    </div>
  );
};

export default TableSearch;
