import { useEffect, useRef, useState } from "react";
import { Portal, Textarea, Kbd } from "@chakra-ui/react";

import SelectColumnDropdown from "../SelectColumnDropdown";

import type { TSelectedColumnOption } from "@/types/common.types";

interface Props {
  text: string;
  setText: (value: string) => void;
  title?: string;
  rows?: number;
  callFrom?: string;
  placeholder?: string;
  position?: "Left" | "Right";
  className?: string;
}

const SelectableTextEditor = ({
  text,
  setText,
  title,
  rows = 3,
  callFrom,
  placeholder = "",
  position = "Left",
  className,
}: Props) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [showOptions, setShowOptions] = useState(false);
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [containerWidth, setContainerWidth] = useState(280);

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    setText(newText);

    const { scrollHeight, clientHeight } = e.target;

    // Auto-expand textarea
    if (scrollHeight > clientHeight) {
      setTextareaHeight(`${scrollHeight}px`);
    } else {
      setTextareaHeight("auto");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const lastKeyPressed = e.key;

    if (lastKeyPressed === "/") {
      const editorPosition = inputRef?.current?.getBoundingClientRect();
      setCursorPosition({ x: editorPosition?.x!, y: editorPosition?.y! });
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    if (selectedOption?.key) {
      const newText = text?.replace(/\/\w*\s?/, `{{${selectedOption.key}}}`);
      setText(newText);
      setSelectedOption(null);
      setShowOptions(false);
    }
  }, [selectedOption]);

  return (
    <div className={className ?? ""}>
      {title && <p className="mt-4 pb-1 text-sm font-semibold ">{title}</p>}
      <div className="relative h-full">
        <Textarea
          rows={rows}
          placeholder={placeholder || "Enter Prompt"}
          ref={inputRef}
          resize={"none"}
          value={text}
          style={{
            height: textareaHeight,
            border: "1px solid #cbd5e0",
            maxHeight: "15rem",
          }}
          className="!focus:outline-none !focus:ring-0 !focus:border-gray-300 !rounded-b-none !rounded-t"
          onChange={handleTextareaChange}
          onKeyDown={handleKeyDown}
        />
        <div className="rounded-none border border-t-0 p-1 px-2 text-sm">
          Type <Kbd>/</Kbd> Insert Column
        </div>

        {showOptions && (
          <Portal>
            <div
              className="absolute z-[9999]"
              style={{
                left:
                  position === "Right"
                    ? cursorPosition.x + 550
                    : cursorPosition.x - containerWidth,
                top: cursorPosition.y,
              }}
            >
              <SelectColumnDropdown
                type="noInput"
                selectedOption={selectedOption}
                setSelectedOption={(option: any) => {
                  setSelectedOption(option);
                  inputRef?.current?.focus();
                }}
                setContainerWidth={setContainerWidth}
                notInportal={true}
                callFrom={callFrom}
              />
            </div>
          </Portal>
        )}
      </div>
    </div>
  );
};

export default SelectableTextEditor;
