import {
  Button,
  Card,
  CardBody,
  Container,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import Icons from "@/components/Icons";

import userService from "@/services/user.service";
import { PlanType, UserData } from "@/types/user.types";
import { useUpdateUserDetails } from "@/hooks/useUserData";

const planOptions = [
  { label: "Free", value: "Free" },
  { label: "Growth", value: "growth" },
  { label: "Pro", value: "pro" },
  { label: "Starter", value: "starter" },
  { label: "Persana Pro", value: "persana_pro" },
] as {
  label: string;
  value: PlanType;
}[];

const PlanChange = () => {
  const toast = useToast({
    position: "top-right",
    isClosable: true,
  });

  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState<UserData | null>(null);
  const [planType, setPlanType] = useState<PlanType>("Free");

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (email: string) => userService.getUserDetailsForAdmins(email),
    onError: (error) => {
      toast({
        title: error.message,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    },
    onSuccess: async (response: any) => {
      if (!response.success) {
        toast({
          title: response.message,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
        return;
      }
      setEmail("");
      setUserData(response.data?.data);
      setPlanType(response.data?.data?.planType);
    },
  });

  const { mutateAsync: updatePlan, isPending: isUpdating } =
    useUpdateUserDetails();

  const handleSearch = useCallback(async () => {
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!isValidEmail) {
      toast({
        title: "Invalid Email",
        status: "error",
      });
      return;
    }
    await mutateAsync(email);
  }, [email]);

  const handleSave = useCallback(async () => {
    await updatePlan(
      {
        planType,
        email: userData?.Email,
      },
      {
        onSuccess: () => {
          toast({
            title: "Plan updated successfully",
            status: "success",
            isClosable: true,
            position: "top-right",
          });
          if (userData?.Email) {
            mutateAsync(userData.Email);
          }
        },
        onError: (error) => {
          toast({
            title: error.message,
            status: "error",
            isClosable: true,
            position: "top-right",
          });
        },
      },
    );
  }, [planType, userData]);

  const isDisabled = !email || isPending;

  console.log("userData", userData, planType);

  return (
    <Container maxW="700px" className="mt-10 p-3 shadow-md">
      <h1 className="mb-4 text-center text-lg">User Plan Change</h1>

      <div className="mb-8 flex gap-10">
        <Input
          placeholder="Search by email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setUserData(null);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <Button
          className={`quick-add !w-[200px] !rounded-md !bg-primary-gradient !px-4 !py-2 !text-white hover:!shadow-md ${
            isDisabled ? "cursor-not-allowed" : ""
          }`}
          disabled={isDisabled}
          onClick={handleSearch}
          isLoading={isPending}
        >
          Search
        </Button>
      </div>

      {!!userData && (
        <Card>
          <CardBody className="flex flex-row items-center justify-between">
            <div>
              <Text fontSize="md">{userData.profileInfo?.firstName}</Text>
              <Text fontSize="sm" className="text-darkGray">
                {userData.Email}
              </Text>
            </div>
            <div className="flex items-center gap-2">
              <Select
                placeholder="Select Plan"
                value={planType}
                onChange={(e) => {
                  setPlanType(e.target.value as PlanType);
                }}
              >
                {planOptions.map((plan) => (
                  <option key={plan.value} value={plan.value}>
                    {plan.label}
                  </option>
                ))}
              </Select>
              {planType !== userData.planType && (
                <Button
                  onClick={() => {
                    handleSave();
                  }}
                  isLoading={isUpdating}
                >
                  <Icons.DoneIcon size={25} className=" text-primaryPurple" />
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      )}
    </Container>
  );
};

export default PlanChange;
