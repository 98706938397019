import {
  useSearchLeadsStore,
  useTabStore,
} from "@/components/PeopleSearch/store.ts";

import { useNatural } from "./useNatural";
import { Box, BoxProps, IconButton, useDisclosure } from "@chakra-ui/react";
import AIAssistant from "./AIInput";
import { useEffect } from "react";
import Icons from "@/components/Icons";
import GPT from "@/assets/svgs/enrichment-gpt.svg";

export const NaturalFilters = (props: BoxProps) => {
  const editDisclosure = useDisclosure();
  const currentTab = useSearchLeadsStore((state) => state.tabId);
  const renameTab = useTabStore((state) => state.renameTab);
  const naturalTemplate = useSearchLeadsStore(
    (state) => state?.naturalFilters?.natural_query,
  );
  const queryTitle = useSearchLeadsStore(
    (state) => state?.naturalFilters?.query_title ?? null,
  );
  const tab = useTabStore((state) =>
    state.tabs.find((t) => t.id === currentTab),
  );
  useEffect(() => {
    if (tab && queryTitle && (tab.isNameStale || !tab.name)) {
      renameTab(tab.id, queryTitle);
    }
  }, [queryTitle, renameTab, tab]);
  const naturalFilters = useNatural(naturalTemplate);
  useEffect(() => {
    if (naturalFilters) {
      editDisclosure.onClose();
    }
  }, [naturalFilters]);
  if (!naturalFilters || editDisclosure.isOpen)
    return (
      <AIAssistant
        onClose={editDisclosure.onClose}
        autoFocus={editDisclosure.isOpen}
      />
    );
  return (
    <Box
      {...props}
      className={"flex items-center gap-2 relative" + props.className ?? ""}
      onDoubleClick={editDisclosure.onOpen}
    >
      <IconButton
        variant={"ghost"}
        size={"xl"}
        colorScheme="purple"
        onClick={editDisclosure.onOpen}
        aria-label="Edit"
        icon={<img src={GPT} alt="GPT" />}
      />
      <span>{naturalFilters}</span>
      <IconButton
        variant={"ghost"}
        size={"xl"}
        colorScheme="purple"
        onClick={editDisclosure.onOpen}
        aria-label="Edit"
        icon={<Icons.EditIcon />}
      />
    </Box>
  );
};
