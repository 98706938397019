import { useRef } from "react";
import { Grid, GridItem, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";

import {
  TabContext,
  useSearchLeadsStore,
  useSyncPeopleSearchState,
  useTabStore,
} from "@/components/PeopleSearch/store.ts";

import FiltersSection from "./FiltersSection";
import Header from "./Header";
import PeopleSearchResultTable from "./PeopleSearchResultTable";
import Sidebar from "./Sidebar";
import ErrorModal from "./ErrorModal";
import { GridProps } from "@chakra-ui/system";

const PeopleSearchComponent = (props: GridProps) => {
  const uniqueSocketIdRef = useRef(`search-${uuidv4()}`);
  const isShowErrorModal = useSearchLeadsStore(
    (state) => state.isShowErrorModal,
  );

  const updateState = useSearchLeadsStore((state) => state.updateState);
  const handleClose = () => {
    updateState({
      isShowErrorModal: false,
    });
  };

  return (
    <>
      <Grid
        {...props}
        py={0}
        as={TabPanel}
        templateRows="repeat(1, 74px 1fr)"
        templateColumns="repeat(1, 25% 1fr)"
        overflowY={"auto"}
        height={"calc(100vh - 60px)"}
      >
        <GridItem colSpan={2}>
          <FiltersSection />
        </GridItem>
        <GridItem className="border border-t-0 border-[#F0EFF1] bg-[#F5F3FF]">
          <Sidebar />
        </GridItem>
        <GridItem overflow="auto" className="relative flex flex-col">
          {/* Table */}
          <div className=" h-full min-h-[550px] px-[16px]">
            <PeopleSearchResultTable
              uniqueSocketId={uniqueSocketIdRef.current}
            />
          </div>
        </GridItem>
      </Grid>
      <ErrorModal
        isShowErrorModal={!!isShowErrorModal}
        handleClose={handleClose}
      />
    </>
  );
};

const PeopleSearch = () => {
  const activeTab = useTabStore((state) => state.activeTab);
  const tabs = useTabStore((state) => state.tabs);
  const updateTabState = useTabStore((state) => state.updateData);
  useSyncPeopleSearchState();
  return (
    <>
      <Tabs
        variant={"enclosed"}
        size={"md"}
        index={activeTab}
        onChange={(index) => updateTabState({ activeTab: index })}
      >
        <TabContext.Provider value={tabs[activeTab].store}>
          <Header />
        </TabContext.Provider>
        <TabPanels>
          {tabs.map((tab) => (
            <TabContext.Provider key={tab.id} value={tab.store}>
              <PeopleSearchComponent key={tab.id} />
            </TabContext.Provider>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
};
export default PeopleSearch;
