import { useEffect, useState } from "react";

import WatchTutorial from "@/components/Common/WatchTutorial";
import SelectTemplate from "./SelectTemplate";

import SelectColumnDropdown from "../../Common/SelectColumnDropdown";
import SelectableTextEditor from "../../Common/SelectableTextEditor";
import NewCustomPreset from "./NewCustomPreset";
import SaveAndRunEnrichment from "../../Common/SaveAndRun";
import Settings from "./Settings";
import { useTableStore } from "@/stores/table.store";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import { useEnrichStore } from "@/stores/enrich.store";

import type { TSelectedColumnOption } from "@/types/common.types";

import { defaultPromptText } from "./constant";
import { REGEX } from "../../constants";
import { EnrichmentMetaData } from "@/types/table.types";
import { userStore } from "@/stores/user.store";
import ConditionalFormula from "../../Common/ConditionalFormula";
import { usePresetsStore } from "@/stores/presets.store";

interface EnrichmentDataType extends EnrichmentMetaData {
  promptText: string;
}

const GenerateSalesEmails = () => {
  const columns = useTableStore((state) => state.tableData.columns);
  const viewMode = useEnrichStore((state) => state.viewMode);
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);
  const { updateState: updatePresetState, selectedPresetValues } =
    usePresetsStore();
  const currentUser = userStore((state) => state.currentUser);

  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);

  const [promptText, setPromptText] = useState(() => defaultPromptText || "");
  const [profileInfo, setProfileInfo] = useState(() => ({
    firstName: currentUser?.profileInfo?.firstName || "",
    companyName: currentUser?.profileInfo?.companyName || "",
    companyDescription: currentUser?.profileInfo?.companyDescription || "",
    callToAction: currentUser?.profileInfo?.callToAction || "",
  }));

  const handleUpdateSettings = (fieldName: string, value: string) => {
    setProfileInfo({
      ...profileInfo,
      [fieldName]: value,
    });
  };

  const payload = {
    ...profileInfo,
    promptText,
    columnId: selectedOption?.keyId,
  };

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;

      if (enrichmentData) {
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        setPromptText(enrichmentData.promptText);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
        });
      }
    } else {
      const initialLinkedInColumn = getInitialColumn(REGEX.LINKEDINPROFILE);
      if (initialLinkedInColumn) {
        setSelectedOption({
          key: initialLinkedInColumn.name,
          keyId: initialLinkedInColumn._id,
          iconType: initialLinkedInColumn.metaData?.iconType || "url",
        });
      }
    }
  }, [columns]);

  useEffect(() => {
    if (selectedPresetValues) {
      setPromptText(selectedPresetValues);
    }
  }, [selectedPresetValues]);

  return (
    <>
      <div className="p-4 overflow-y-auto max-h-[70vh]">
        <WatchTutorial
          subText="Learn how to use: Generate Sales Emails."
          link={""}
        />

        <SelectTemplate />

        <div>
          <p className="pb-1 text-base font-semibold">LinkedIn Url</p>
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <Settings
          handleUpdateSettings={handleUpdateSettings}
          profileInfo={profileInfo}
        />
        <div className="">
          <SelectableTextEditor
            text={promptText}
            setText={(newText) => {
              setPromptText(newText);
              updatePresetState({
                selectedPresetValues: newText,
              });
            }}
            rows={6}
            title="Write your prompt (our AI will use your company information above)"
          />
          <NewCustomPreset />
        </div>
        <ConditionalFormula />
      </div>

      <SaveAndRunEnrichment
        isDisabled={!promptText || !selectedOption?.keyId}
        apiPayload={payload}
      />
    </>
  );
};

export default GenerateSalesEmails;
