import React from "react";
import { NodeToolbar, Position } from "@xyflow/react";

import Toolbar from "./Toolbar";
import CustomNodeCard from "../CustomNodeCard";
import { CustomNodeProps } from "..";

import TableImg from "@/assets/svgs/auto-pilot-table.svg";

const PersanaTable: React.FC<CustomNodeProps> = ({ data }) => {
  return (
    <>
      {!data.isDisabled && data.showToolbar && (
        <NodeToolbar isVisible={true} position={Position.Right}>
          <Toolbar tableId={data?.tableData?._id || ""} />
        </NodeToolbar>
      )}
      <CustomNodeCard
        title="People Table"
        subtitle="Table"
        configStatus="Table Generated"
        isConfigured={!data.isDisabled}
        isDisabled={!!data.isDisabled}
        imgSrc={TableImg}
      />
    </>
  );
};

export default PersanaTable;
