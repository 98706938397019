import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import ProcessingBar from "@/components/Common/ProcessingBar";
import Icons from "@/components/Icons";
import CancelProcess from "./CancelProcess";

import { filterComponentMaps } from "@/components/Enrichment/FilterModals/mappings";
import { useProcessingStore } from "@/stores/processing.store";
import { useTableStore } from "@/stores/table.store";
import { RunningProcessesData } from "@/types/table.types";
import { processingTypeLabelMap } from "../../constants";
import {
  tableActionIcons,
  TTableActionIconKeys,
} from "@/components/Icons/tableActionIcons";

const RunningProcesses = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const processesData = useProcessingStore((state) => state.processingData);
  const tableId = useTableStore((state) => state.tableData._id);
  if (!processesData?.length) return null;

  const enrichmentProcesses = (processesData.filter(
    (item) => item.processingType === "enrichment",
  ) || []) as RunningProcessesData[];

  const _otherProcesses = processesData.filter(
    (item) => item.processingType !== "enrichment",
  );
  const allProcesses = [...enrichmentProcesses, ..._otherProcesses].filter(
    (item) => item._id,
  ) as RunningProcessesData[];

  if (!allProcesses.length) return null;

  const firstProcess = allProcesses[0];

  const processedPercentage =
    ((firstProcess?.metaData?.processedData || 0) /
      (firstProcess?.metaData?.totalDataToProcess || 0)) *
      100 || 0;

  const otherProcesses = allProcesses.slice(1);
  const getHeaderIcon = (process: RunningProcessesData) => {
    try {
      const columnName = process.metaData?.payload?.newColumnName;
      const columnMetaData = process.metaData?.payload?.columns.find(
        (column: ANY) => column.name === columnName,
      )?.metaData;
      const { iconType, iconImg } = columnMetaData;
      if (iconType === "image") {
        return <img src={iconImg} className="size-3" />;
      } else {
        const Icon = tableActionIcons[iconType as TTableActionIconKeys];
        // @ts-expect-error ignore type
        return Icon ? <Icon size={16} /> : <Icons.Processor size={16} />;
      }
    } catch (error) {
      return <Icons.Processor size={16} />;
    }
  };
  const getEnrichmentName = (process: RunningProcessesData) => {
    const enrichmentLabel = (process?.metaData?.payload?.bodyData
      ?.enrichmentName || "") as keyof typeof filterComponentMaps;

    return (
      filterComponentMaps[enrichmentLabel]?.title ||
      processingTypeLabelMap[process.processingType] ||
      process?.processingType ||
      enrichmentLabel
    );
  };

  return (
    <>
      <div className="flex flex-col gap-0 overflow-hidden">
        <div className="flex h-[1em] items-center gap-3">
          <div className="flex items-center gap-2 text-sm">
            <span className="flex flex-1 flex-row items-center gap-1">
              {getHeaderIcon(firstProcess)}
              {firstProcess ? getEnrichmentName(firstProcess) : ""}
            </span>
            <div
              className={`max-w-[400px] ${firstProcess?.metaData?.totalDataToProcess ? "w-[200px]" : "w-[100px]"}`}
            >
              <ProcessingBar
                processedPercentage={Math.round(processedPercentage)}
                processedRows={firstProcess?.metaData?.processedData || 0}
                totalRows={
                  firstProcess?.processingType === "auto_run"
                    ? 0
                    : firstProcess?.metaData?.totalDataToProcess || 0
                }
                isQueued={firstProcess.isQueued}
              />
            </div>
          </div>
          <div>
            {firstProcess.metaData?.isNotCancellable ? null : (
              <CancelProcess
                tableId={tableId}
                processingId={firstProcess._id}
              />
            )}
            {!!otherProcesses.length && (
              <Button
                variant={"ghost"}
                size={"xs"}
                onClick={onOpen}
                rightIcon={<Icons.MoreDoubleIcon />}
              >
                +{otherProcesses.length}
              </Button>
            )}
          </div>
        </div>
        <div className="flex h-[1em] items-center justify-between">
          <p className="w-full text-left text-xs font-light text-darkTextGray">
            {firstProcess.isQueued
              ? "Queued"
              : firstProcess.processingMessage || ""}
          </p>
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody className="mt-4">
            {otherProcesses.map((process) => (
              <div key={process._id} className="flex items-center gap-3">
                <div>
                  <p className="flex-1">{getEnrichmentName(process)}</p>
                  <p className="w-full text-left text-sm font-light text-darkTextGray">
                    {process.isQueued
                      ? "Queued"
                      : process.processingMessage || ""}
                  </p>
                </div>

                <div
                  className={`max-w-[400px] ${firstProcess?.metaData?.totalDataToProcess ? "w-[200px]" : "w-[100px]"}`}
                >
                  <ProcessingBar
                    processedPercentage={
                      (process.metaData?.processedData /
                        process.metaData?.totalDataToProcess) *
                      100
                    }
                    processedRows={process.metaData?.processedData}
                    totalRows={
                      process.processingType === "auto_run"
                        ? 0
                        : process.metaData?.totalDataToProcess
                    }
                    isQueued={process.isQueued}
                  />
                </div>
                <CancelProcess tableId={tableId} processingId={process._id} />
              </div>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RunningProcesses;
