import leadService from "@/services/leads.service";
import { useModalStore } from "@/stores/modal.store";
import { userStore } from "@/stores/user.store";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Button,
  Input,
  NumberInput,
  NumberInputField,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";

const SalesNavImportModal = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpenModal, modalData, updateState } = useModalStore(
    (state) => state,
  );

  const currentUser = userStore((state) => state.currentUser);

  const [numberOfLeads, setNumberOfLeads] = useState(1);
  const [salesNavUrl, setSalesNavUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isProUser = ["pro", "persana_pro"].includes(
    currentUser?.planType ?? "",
  );

  const onClose = () => {
    setNumberOfLeads(1);
    updateState({
      isOpenModal: false,
      modalData: {
        modalType: "",
        metaData: {},
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSalesNavUrl(value);
  };

  const handleImport = async () => {
    setIsLoading(true);
    const res = await leadService.salesNavImport(numberOfLeads, salesNavUrl);

    if (res?.success) {
      const tableId = res?.data?.data?.tableId;
      if (tableId) {
        onClose();
        navigate({
          to: "/table/$tableId",
          params: {
            tableId,
          },
        });
        setSalesNavUrl("");
        setNumberOfLeads(1);
        onClose();
      } else {
        toast({
          position: "top-right",
          title: "Contacts not found",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
      }
    } else {
      toast({
        position: "top-right",
        title: res?.error?.message || "Something went wrong",
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const openPricingModal = () => {
    updateState({
      isOpenModal: true,
      modalData: { modalType: "pricing" },
    });
  };

  return (
    <>
      <Modal
        onClose={() => {
          onClose();
        }}
        isOpen={isOpenModal && modalData.modalType === "sales-nav-import"}
        size={"2xl"}
        scrollBehavior="outside"
        isCentered
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
        <ModalContent className="pb-4 font-poppins">
          <ModalHeader className="border-b-dividerColor border-b-[1px] text-center">
            <div className="d-flex items-center">
              <Text className="flex-1">Import using Sales Navigator URL</Text>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p className="text-gray-800 mb-2 text-[16px] font-semibold">
                Important Guidelines for Using This Source:
              </p>
              <div className="text-gray-700 space-y-2 text-[14px]">
                <div className="flex items-start">
                  <span className="text-gray-600 mr-2">•</span>
                  <p>
                    Ensure your URL is specifically a Sales Navigator People
                    Search link, not a Company Search one.
                  </p>
                </div>
                <div className="flex items-start">
                  <span className="text-gray-600 mr-2">•</span>
                  <p>
                    Avoid using Lead Lists saved to your personal account in
                    your People Search filters.
                  </p>
                </div>
                <div className="flex items-start">
                  <span className="text-gray-600 mr-2">•</span>
                  <p>
                    While the data is being processed (typically takes around 30
                    minutes, but it could take up to 90 minutes), feel free to
                    continue working on other tasks within Persana or even close
                    the window. The results will be automatically added once
                    they're ready.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-4 flex items-center gap-2 pb-1">
              <p className="text-sm font-semibold ">
                Sales Navigator Search URL
              </p>
            </div>
            <div className="relative h-full">
              <Input
                type="text"
                resize="none"
                placeholder="https://www.linkedin.com/sales/people/123456789-john-doe"
                value={salesNavUrl}
                onChange={handleChange}
              />
            </div>

            <div className="mt-4 flex items-center gap-2 pb-1">
              <p className="text-sm font-semibold ">Number of leads to fetch</p>
            </div>
            <div className="relative h-full">
              <NumberInput
                min={1}
                max={1000}
                value={numberOfLeads}
                onChange={(val) => {
                  setNumberOfLeads(Number(val));
                }}
              >
                <NumberInputField />
              </NumberInput>
            </div>

            <div className="mt-[20px] flex w-full items-center justify-center">
              <Button
                isDisabled={!numberOfLeads && !salesNavUrl}
                isLoading={isLoading}
                variant="solid"
                h={"38px"}
                fontSize={14}
                className="!bg-primary-gradient hover:opacity-80"
                colorScheme="primary"
                borderRadius={"6px"}
                onClick={() => {
                  isProUser ? handleImport() : openPricingModal();
                }}
              >
                <span className="font-semibold">
                  {!isProUser ? "Upgrade to pro" : "Import"}
                </span>
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SalesNavImportModal;
